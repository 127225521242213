import React from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Table } from "antd";

const demoData = [
  { field: "Çalışma Ortamı ve Koşulları", score: 80 },
  { field: "Maaş Oranları", score: 70 },
  { field: "Sosyal Haklar", score: 75 },
  { field: "Eğitim", score: 60 },
  { field: "Kariyer Beklentisi", score: 65 },
  { field: "Firma İmajı", score: 72 },
  { field: "İletişim", score: 68 },
  { field: "Liderlik ve Yönetim Anlayışı", score: 55 },
  { field: "Yetki ve Sorumluluklar", score: 62 },
  { field: "İş Yapısı ve İş Organizasyonu", score: 78 },
];

const xcolumns = [
  {
    title: "Alan",
    dataIndex: "field",
    key: "field",
  },
  {
    title: "Skor",
    dataIndex: "score",
    key: "score",
  },
];

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#AF19FF",
  "#FF6361",
  "#D7263D",
  "#1F77B4",
  "#FF7F0E",
  "#2CA02C",
];

const topNegativeQuestions = [
  {
    question:
      "Çalışma ortamım, işimi kolay ve rahat yapabilmem için uygundur (sıcak, soğuk, gürültü, kalabalık, dar alan, vb)",
    negativeResponses: 60,
  },
  {
    question: "Çay, kahve vb. içeçek ikramları yeterli ve uygundur.",
    negativeResponses: 55,
  },
  {
    question: "Yemekhane hizmetleri kalitelidir.",
    negativeResponses: 50,
  },
  {
    question: "Çalışma saatlerim ve mola sürelerim yeterli ve uygundur.",
    negativeResponses: 48,
  },
  {
    question: "Ulaşım / servis hizmetleri kalitelidir.",
    negativeResponses: 45,
  },
];

const columns = [
  {
    title: "Soru",
    dataIndex: "question",
    key: "question",
  },
  {
    title: "Negatif Cevap (%)",
    dataIndex: "negativeResponses",
    key: "negativeResponses",
  },
];

const TopNegativeQuestions = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      height: "100%",
    }}
  >
    <p className="id__active-header">Negatif Cevaplar</p>
    <Table
      columns={columns}
      dataSource={topNegativeQuestions}
      pagination={false}
      rowKey="question"
    />
  </div>
);

const topQuestions = [
  {
    question:
      "Yönetimin, şirketle ilgili konularda (hedefler, ürünler ve faaliyet sonuçları, vb) çalışanlara bilgi vermesi yeterlidir.",
    positiveResponses: 95,
  },
  {
    question:
      "Bölüm içindeki çalışanlar arasında işbirliği ve iletişim yeterlidir.",
    positiveResponses: 92,
  },
  {
    question:
      "Yöneticim bölümsel ve kişisel hedeflerin belirlenmesinde çalışanların görüş ve önerilerine açıktır.",
    positiveResponses: 90,
  },
  {
    question:
      "Yöneticim bir hata yaptığımda uyarılarını beni rencide etmeden yapar.",
    positiveResponses: 88,
  },
  {
    question:
      "Terfi imkanları sağlanırken kadın ve erkekler için fırsat eşitliğine önem verilir.",
    positiveResponses: 85,
  },
];

const columnsP = [
  {
    title: "Soru",
    dataIndex: "question",
    key: "question",
  },
  {
    title: "Pozitif Cevap (%)",
    dataIndex: "positiveResponses",
    key: "positiveResponses",
  },
];

const TopPositiveQuestions = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      height: "100%",
    }}
  >
    <p className="id__active-header">Pozitif Cevaplar</p>
    <Table
      columns={columnsP}
      dataSource={topQuestions}
      pagination={false}
      rowKey="question"
    />
  </div>
);

const topManagers = [
  { name: "Ayşe Çelik", rating: 95 },
  { name: "Ahmet Yılmaz", rating: 92 },
  { name: "Hasan Kaya", rating: 90 },
];

const TopRatedManagers = () => (
  <div style={{ width: "300px", height: "300px" }}>
    <p className="id__active-header">En Çok Puanlanan Yöneticiler</p>
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={topManagers}
          dataKey="rating"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={100}
          fill="#8884d8"
          label
        >
          {topManagers.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  </div>
);

const SurveyPage = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      overflow: "scroll",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
        minHeight: "400px",
        width: "100%",
      }}
    >
      <div
        className="drt__table"
        style={{
          height: "380px",
          padding: "10px",
        }}
      >
        <ResponsiveContainer>
          <BarChart data={demoData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="field" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="score" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div
        className="drt__table"
        style={{
          height: "380px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <TopPositiveQuestions />
      </div>
      <div
        className="drt__table"
        style={{
          height: "380px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <TopNegativeQuestions />
      </div>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
        minHeight: "400px",
        width: "100%",
      }}
    >
      <div
        className="drt__table"
        style={{
          height: "380px",
          width: "300px",
          padding: "10px",
          overflow: "hidden",
        }}
      >
        <TopRatedManagers />
      </div>
      <div
        className="drt__table"
        style={{
          height: "380px",
          width: "300px",
          padding: "10px",
        }}
      >
        <Table
          columns={xcolumns}
          dataSource={demoData}
          pagination={false}
          rowKey="field"
        />
      </div>
    </div>
  </div>
);

export default SurveyPage;

/**
 *  <div
      className="drt__table"
      style={{
        height: "380px",
      }}
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={demoData}
            dataKey="score"
            nameKey="field"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#8884d8"
            label
          >
            {demoData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
    <div
      className="drt__table"
      style={{
        width: "45%",
      }}
    >
      <TopRatedManagers />
    </div>


    <div className="drt__table">
      <h3>Survey Details</h3>
      <table
        border="1"
        cellPadding="10"
        cellSpacing="0"
        style={{ width: "100%", textAlign: "left" }}
      >
        <thead>
          <tr>
            <th>Field</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {demoData.map((item, index) => (
            <tr key={index}>
              <td>{item.field}</td>
              <td>{item.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
 */
