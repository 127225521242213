import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import { Header } from "../components/Header";
import "./Home.css";
import { useEffect, useState } from "react";
import academy from "../assets/academy.png";
import { tR } from "../components/functions";
import { Link } from "react-router-dom";
import { RightCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { CustomTable } from "../components/CustomTable";
import exportUsers from "../assets/exportUsers.png";
import clearFilter from "../assets/clearFilter.png";
import { Tooltip as AntdTooltip } from "antd";
import {
  renderActiveShape,
  renderCustomizedLabelPercentage,
  renderCustomizedLabelValue,
  pieColors,
  exportToExcel,
  ShortenText,
} from "../components/functions";

const visitorData = [
  { name: "Mobil", value: 680 },
  { name: "Web", value: 554 },
];

const totalVisitors = visitorData[0].value + visitorData[1].value;

const storeData = [
  { name: "Aktif Bayi", value: 12 },
  { name: "İnaktif Bayi", value: 2 },
];

const totalStores = storeData[0].value + storeData[1].value;

const dummyAdmins = [
  {
    id: "1",
    name: "Mert Avcıoğlu",
    role: "Super Admin",
  },
  {
    id: "2",
    name: "Akif Kaya",
    role: "Admin",
  },
  {
    id: "3",
    name: "Sencer Oztufekci",
    role: "Sales Admin",
  },
  {
    id: "4",
    name: "Mert Avcıoğlu",
    role: "Super Admin",
  },
  {
    id: "5",
    name: "Akif Kaya",
    role: "Admin",
  },
  {
    id: "6",
    name: "Sencer Oztufekci",
    role: "Sales Admin",
  },
  {
    id: "7",
    name: "Mert Avcıoğlu",
    role: "Super Admin",
  },
  {
    id: "8",
    name: "Akif Kaya",
    role: "Admin",
  },
  {
    id: "9",
    name: "Sencer Oztufekci",
    role: "Sales Admin",
  },
  {
    id: "10",
    name: "Mert Avcıoğlu",
    role: "Super Admin",
  },
  {
    id: "11",
    name: "Akif Kaya",
    role: "Admin",
  },
  {
    id: "12",
    name: "Sencer Oztufekci",
    role: "Sales Admin",
  },
];

const columnsMessages = [
  {
    key: "title",
    dataIndex: "title",
    title: "Konu Başlığı",
    sorter: (a, b) => a.title.localeCompare(b.title),
  },
  {
    key: "content",
    dataIndex: "content",
    title: "İçerik",
    sorter: (a, b) => a.content.localeCompare(b.content),
    render: (_, record) => ShortenText(record.content, 70),
  },
  {
    key: "user",
    dataIndex: "user",
    title: "Oluşturan",
    width: 150,
    sorter: (a, b) => a.user.name.localeCompare(b.user.name),
    render: (_, record) => (
      <Link
        state={{ id: record?.user?.id }}
        className="table__link-name"
        to={`/drtid`}
      >
        {record?.user?.name} {record?.user?.surname}
      </Link>
    ),
  },
  {
    key: "isRead",
    dataIndex: "isRead",
    title: "Durum",
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    render: (_, record) => (record.isRead ? "Okundu" : "Okunmadı"),
  },
  {
    key: "createdAt",
    dataIndex: "createdAt",
    title: "Oluşturulma Tarihi",
    defaultSortOrder: "descend",
    width: 150,
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    render: (_, record) => dayjs(record.createdAt).format("DD/MM/YYYY HH:mm"),
  },
];

export const Home = () => {
  const [academyList, setAcademyList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [storyList, setStoryList] = useState([]);
  const [firstPie, setFirstPie] = useState([]);
  const [secondPie, setSecondPie] = useState([]);
  const [thirdPie, setThirdPie] = useState([]);
  const [fourthPie, setFourthPie] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [messages, setMessages] = useState([]);
  const [clearFiltersClicked, setClearFiltersClicked] = useState(false);

  const [activeIndex1, setActiveIndex1] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const [activeIndex3, setActiveIndex3] = useState(0);
  const [activeIndex4, setActiveIndex4] = useState(0);

  const [expandedRowKey, setExpandedRowKey] = useState(null);

  const handleExpand = async (record) => {
    setExpandedRowKey((prevKey) => (prevKey === record.id ? null : record.id));

    if (record?.isRead === false) {
      await tR(`/messages/${record.id}`, "PATCH", { isRead: true });
      getMessages();
    }
  };

  useEffect(() => {
    getAcademyList();
    getStoryList();
    getNewsList();
    getMessages();
  }, []);

  useEffect(() => {
    setFirstPie([
      { name: "Mobil", value: 680 },
      { name: "Web", value: 554 },
    ]);
    setSecondPie([
      { name: "Aktif Gönderi", value: academyList?.length || 0 },
      { name: "Aktif Haber", value: newsList?.length || 0 },
      {
        name: "Aktif Story",
        value: Math.min(storyList?.length.toLocaleString("tr-TR"), 10) || 0,
      },
    ]);
    setThirdPie([
      { name: "Aktif Bayi", value: storeData[0].value },
      { name: "İnaktif Bayi", value: storeData[1].value },
    ]);
    setFourthPie([
      { name: "Super Admin", value: 2 },
      { name: "Admin", value: 3 },
      { name: "Sales Admin", value: 7 },
    ]);
  }, [academyList, newsList, storyList]);

  const getMessages = async () => {
    const _messages = await tR(`/messages`, "GET");
    setMessages(_messages);
  };

  const getAcademyList = async () => {
    const _academyList = await tR("/academy/published", "GET");
    console.log(_academyList);
    setAcademyList(_academyList);
  };

  const getStoryList = async () => {
    const _storyList = await tR("/stories", "GET");
    setStoryList(_storyList);
  };

  const getNewsList = async () => {
    const _newsList = await tR("/news/published", "GET");
    setNewsList(_newsList);
  };

  const excelExportConfig = {
    mappingFunction: ({ id, title, user, content, createdAt }) => ({
      ID: id,
      Başlık: title,
      İçerik: content,
      Oluşturan: `${user?.name} ${user?.surname}`,
      "Oluşturulma Tarihi": dayjs(createdAt).format("DD/MM/YYYY HH:mm"),
    }),
  };

  return (
    <div className="outer__container">
      <Header title="Ana Sayfa" />
      <div className="home__content">
        <div className="page-blur01"></div>
        <div className="home__left">
          <div className="drt__table" style={{ overflow: "hidden" }}>
            <div className="table__header-container">
              <div className="table__header-left">
                <img src={academy} alt="logo" className="table__big-icon" />
                <p className="table__header-title">Sistem Trafiği</p>
              </div>
              <div className="table__header-right">
                <p className="home__left-header-title">Toplam Kullanıcı</p>
                <p className="home__left-header-text">
                  {totalVisitors.toLocaleString("tr-TR")}
                </p>
              </div>
            </div>
            <div className="home__left-content">
              <ResponsiveContainer height="100%" width="65%">
                <PieChart>
                  <Pie
                    activeIndex={activeIndex1}
                    activeShape={renderActiveShape}
                    onMouseEnter={(entry, index) => {
                      setActiveIndex1(index);
                    }}
                    isAnimationActive={true}
                    dataKey="value"
                    data={firstPie}
                    fill="#8884d8"
                    labelLine={false}
                    label={(value) => renderCustomizedLabelPercentage(value)}
                  >
                    {firstPie.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={pieColors[index % pieColors.length]}
                      />
                    ))}
                  </Pie>
                  <defs>
                    <linearGradient
                      id="gradient1"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#13c5a9 " />
                      <stop offset="100%" stopColor="#154a4a " />
                    </linearGradient>
                    <linearGradient
                      id="gradient2"
                      x1="25%"
                      y1="25%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#ffaa00" />
                      <stop offset="100%" stopColor="#e3c082" />
                    </linearGradient>
                    <linearGradient
                      id="gradient3"
                      x1="0%"
                      y1="25%"
                      x2="0%"
                      y2="75%"
                    >
                      <stop offset="0%" stopColor="#ec1818" />
                      <stop offset="100%" stopColor="#dd7878" />
                    </linearGradient>
                    <linearGradient
                      id="gradient4"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#8B4513" />
                      <stop offset="100%" stopColor="#CD853F" />
                    </linearGradient>
                    <linearGradient
                      id="gradient5"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#269798" />
                      <stop offset="100%" stopColor="#ff7ae6" />
                    </linearGradient>
                  </defs>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <div className="home__left-numbers">
                <div className="home__left-number">
                  <p className="home__left-number-title">
                    <RightCircleFilled
                      style={{
                        color: "aqua",
                        background: "aqua",
                        borderRadius: "50%",
                        fontSize: 7,
                        verticalAlign: "middle",
                        marginRight: 5,
                      }}
                    />
                    Mobil Kullanıcı
                  </p>
                  <p className="home__left-number-value">
                    {visitorData[0].value.toLocaleString("tr-TR")}
                  </p>
                </div>
                <div className="home__left-number">
                  <p className="home__left-number-title">
                    <RightCircleFilled
                      style={{
                        color: "orange",
                        background: "orange",
                        borderRadius: "50%",
                        fontSize: 7,
                        verticalAlign: "middle",
                        marginRight: 5,
                      }}
                    />
                    Web Kullanıcı
                  </p>
                  <p className="home__left-number-value">
                    {visitorData[1].value.toLocaleString("tr-TR")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="drt__table" style={{ overflow: "hidden" }}>
            <div className="table__header-container">
              <div className="table__header-left">
                <img src={academy} alt="logo" className="table__big-icon" />
                <p className="table__header-title">DRT Çiftçi Akademi</p>
              </div>
            </div>
            <div className="home__left-content">
              <ResponsiveContainer height="100%" width="65%">
                <PieChart>
                  <Pie
                    activeIndex={activeIndex2}
                    activeShape={renderActiveShape}
                    onMouseEnter={(entry, index) => {
                      setActiveIndex2(index);
                    }}
                    isAnimationActive={true}
                    dataKey="value"
                    data={secondPie}
                    fill="#8884d8"
                    labelLine={false}
                    label={(value) => renderCustomizedLabelValue(value)}
                  >
                    {secondPie.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={pieColors[index % pieColors.length]}
                      />
                    ))}
                  </Pie>
                  <defs>
                    <linearGradient
                      id="gradient1"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#13c5a9 " />
                      <stop offset="100%" stopColor="#154a4a " />
                    </linearGradient>
                    <linearGradient
                      id="gradient2"
                      x1="25%"
                      y1="25%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#ffaa00" />
                      <stop offset="100%" stopColor="#e3c082" />
                    </linearGradient>
                    <linearGradient
                      id="gradient3"
                      x1="0%"
                      y1="25%"
                      x2="0%"
                      y2="75%"
                    >
                      <stop offset="0%" stopColor="#ec1818" />
                      <stop offset="100%" stopColor="#dd7878" />
                    </linearGradient>
                    <linearGradient
                      id="gradient4"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#8B4513" />
                      <stop offset="100%" stopColor="#CD853F" />
                    </linearGradient>
                    <linearGradient
                      id="gradient5"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#269798" />
                      <stop offset="100%" stopColor="#ff7ae6" />
                    </linearGradient>
                  </defs>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <div className="home__left-numbers">
                <div className="home__left-number">
                  <p className="home__left-number-title">
                    <RightCircleFilled
                      style={{
                        color: "aqua",
                        background: "aqua",
                        borderRadius: "50%",
                        fontSize: 7,
                        verticalAlign: "middle",
                        marginRight: 5,
                      }}
                    />
                    Aktif Gönderi
                  </p>
                  <p className="home__left-number-value">
                    {academyList?.length.toLocaleString("tr-TR") || 0}
                  </p>
                </div>
                <div className="home__left-number">
                  <p className="home__left-number-title">
                    <RightCircleFilled
                      style={{
                        color: "orange",
                        background: "orange",
                        borderRadius: "50%",
                        fontSize: 7,
                        verticalAlign: "middle",
                        marginRight: 5,
                      }}
                    />
                    Aktif Haber
                  </p>
                  <p className="home__left-number-value">
                    {newsList?.length.toLocaleString("tr-TR") || 0}
                  </p>
                </div>
                <div className="home__left-number">
                  <p className="home__left-number-title">
                    <RightCircleFilled
                      style={{
                        color: "red",
                        fontSize: 7,
                        verticalAlign: "middle",
                        marginRight: 5,
                      }}
                    />
                    Aktif Story
                  </p>
                  <p className="home__left-number-value">
                    {Math.min(storyList?.length.toLocaleString("tr-TR"), 10)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="drt__table" style={{ overflow: "hidden" }}>
            <div className="table__header-container">
              <div className="table__header-left">
                <img src={academy} alt="logo" className="table__big-icon" />
                <p className="table__header-title">Bayiler</p>
              </div>
              <div className="table__header-right">
                <p className="home__left-header-title">Toplam Bayi</p>
                <p className="home__left-header-text">
                  {totalStores.toLocaleString("tr-TR")}
                </p>
              </div>
            </div>
            <div className="home__left-content">
              <ResponsiveContainer height="100%" width="65%">
                <PieChart>
                  <Pie
                    activeIndex={activeIndex3}
                    activeShape={renderActiveShape}
                    onMouseEnter={(entry, index) => {
                      setActiveIndex3(index);
                    }}
                    isAnimationActive={true}
                    dataKey="value"
                    data={thirdPie}
                    fill="#8884d8"
                    labelLine={false}
                    label={(value) => renderCustomizedLabelValue(value)}
                  >
                    {thirdPie.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={pieColors[index % pieColors.length]}
                      />
                    ))}
                  </Pie>
                  <defs>
                    <linearGradient
                      id="gradient1"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#13c5a9 " />
                      <stop offset="100%" stopColor="#154a4a " />
                    </linearGradient>
                    <linearGradient
                      id="gradient2"
                      x1="25%"
                      y1="25%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#ffaa00" />
                      <stop offset="100%" stopColor="#e3c082" />
                    </linearGradient>
                    <linearGradient
                      id="gradient3"
                      x1="0%"
                      y1="25%"
                      x2="0%"
                      y2="75%"
                    >
                      <stop offset="0%" stopColor="#ec1818" />
                      <stop offset="100%" stopColor="#dd7878" />
                    </linearGradient>
                    <linearGradient
                      id="gradient4"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#8B4513" />
                      <stop offset="100%" stopColor="#CD853F" />
                    </linearGradient>
                    <linearGradient
                      id="gradient5"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#269798" />
                      <stop offset="100%" stopColor="#ff7ae6" />
                    </linearGradient>
                  </defs>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <div className="home__left-numbers">
                <div className="home__left-number">
                  <p className="home__left-number-title">
                    <RightCircleFilled
                      style={{
                        color: "aqua",
                        background: "aqua",
                        borderRadius: "50%",
                        fontSize: 7,
                        verticalAlign: "middle",
                        marginRight: 5,
                      }}
                    />
                    Aktif Bayi
                  </p>
                  <p className="home__left-number-value">
                    {storeData[0].value.toLocaleString("tr-TR")}
                  </p>
                </div>
                <div className="home__left-number">
                  <p className="home__left-number-title">
                    <RightCircleFilled
                      style={{
                        color: "orange",
                        background: "orange",
                        borderRadius: "50%",
                        fontSize: 7,
                        verticalAlign: "middle",
                        marginRight: 5,
                      }}
                    />
                    İnaktif Bayi
                  </p>
                  <p className="home__left-number-value">
                    {storeData[1].value.toLocaleString("tr-TR")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home__right">
          <div
            className="drt__table"
            style={{ overflow: "hidden", flexDirection: "row" }}
          >
            <div className="home__right-admins">
              <div className="table__header-container">
                <div className="table__header-left">
                  <img src={academy} alt="logo" className="table__big-icon" />
                  <p className="table__header-title">Yöneticiler</p>
                </div>
              </div>
              <div className="home__right-admins-table">
                {dummyAdmins.map((admin) => (
                  <div className="home__right-admin">
                    <p className="home__right-admin-name">{admin.name}</p>
                    <p className="home__right-admin-role">{admin.role}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="home__right-admins-pie-container">
              <ResponsiveContainer height="100%" width="100%">
                <PieChart>
                  <Pie
                    activeIndex={activeIndex4}
                    activeShape={renderActiveShape}
                    onMouseEnter={(entry, index) => {
                      setActiveIndex4(index);
                    }}
                    isAnimationActive={true}
                    dataKey="value"
                    data={fourthPie}
                    fill="#8884d8"
                    labelLine={false}
                    label={(value) => renderCustomizedLabelValue(value)}
                  >
                    {fourthPie.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={pieColors[index % pieColors.length]}
                      />
                    ))}
                  </Pie>
                  <defs>
                    <linearGradient
                      id="gradient1"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#13c5a9 " />
                      <stop offset="100%" stopColor="#154a4a " />
                    </linearGradient>
                    <linearGradient
                      id="gradient2"
                      x1="25%"
                      y1="25%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#ffaa00" />
                      <stop offset="100%" stopColor="#e3c082" />
                    </linearGradient>
                    <linearGradient
                      id="gradient3"
                      x1="0%"
                      y1="25%"
                      x2="0%"
                      y2="75%"
                    >
                      <stop offset="0%" stopColor="#ec1818" />
                      <stop offset="100%" stopColor="#dd7878" />
                    </linearGradient>
                    <linearGradient
                      id="gradient4"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#8B4513" />
                      <stop offset="100%" stopColor="#CD853F" />
                    </linearGradient>
                    <linearGradient
                      id="gradient5"
                      x1="25%"
                      y1="50%"
                      x2="100%"
                      y2="0%"
                    >
                      <stop offset="0%" stopColor="#269798" />
                      <stop offset="100%" stopColor="#ff7ae6" />
                    </linearGradient>
                  </defs>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="drt__table" style={{ flex: 2 }}>
            <div className="table__header-container">
              <div className="table__header-left">
                <img src={academy} alt="logo" className="table__big-icon" />
                <p className="table__header-title">Mesajlar</p>
              </div>
              <div className="table__header-right">
                <AntdTooltip title="Filtreleri Sıfırla">
                  <button
                    onClick={() => {
                      setClearFiltersClicked(true);
                      setTimeout(() => {
                        setClearFiltersClicked(false);
                      }, 100);
                    }}
                    className="table__small-icon-container"
                  >
                    <img
                      src={clearFilter}
                      alt="clearFilter"
                      className="table__small-icon"
                    />
                  </button>
                </AntdTooltip>
                <AntdTooltip title="Excel'e Aktar">
                  <button
                    className="table__small-icon-container"
                    onClick={() =>
                      exportToExcel(selectedItems, excelExportConfig)
                    }
                  >
                    <img
                      src={exportUsers}
                      alt="exportUsers"
                      className="table__small-icon"
                    />
                  </button>
                </AntdTooltip>
              </div>
            </div>
            <div className="table__container">
              <CustomTable
                tableName={"MessagesList"}
                columns={columnsMessages}
                dataSource={messages}
                setSelectedItems={setSelectedItems}
                clearFiltersClicked={clearFiltersClicked}
                expandable={{
                  expandedRowRender: (record) => (
                    <div style={{ padding: 8, lineHeight: `15px` }}>
                      {record.content}
                    </div>
                  ),
                  expandedRowKeys: [expandedRowKey],
                  onExpand: (expanded, record) => handleExpand(record),
                  rowExpandable: (record) => record.expandedContent !== "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
