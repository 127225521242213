import { Dropdown, Input, Menu, Tooltip } from "antd";
import { Header } from "../components/Header";
import "./StoreManagement.css";
import { CustomTable } from "../components/CustomTable";
import DRT_Logo from "../assets/DRT_Logo.png";
import exportUsers from "../assets/exportUsers.png";
import moreIcon from "../assets/moreIcon.png";
import clearFilter from "../assets/clearFilter.png";
import data from "../data.json";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { exportToExcel, filterContentOption } from "../components/functions";

const columnStores = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
  },
  {
    dataIndex: "company",
    title: "İşletme",
    sorter: (a, b) => a.company.localeCompare(b.company),
  },
  {
    dataIndex: "phone",
    title: "Cep Telefonu",
  },
  {
    dataIndex: "phoneSabit",
    title: "Sabit Telefon",
  },
  {
    dataIndex: "email",
    title: "E-Posta",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Ad",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    key: "surname",
    dataIndex: "surname",
    title: "Soyad",
    sorter: (a, b) => a.surname.localeCompare(b.surname),
  },
  {
    key: "city",
    dataIndex: "city",
    title: "İl",
    sorter: (a, b) => a.city.localeCompare(b.city),
  },
  {
    key: "district",
    dataIndex: "district",
    title: "İlçe",
    sorter: (a, b) => a.district.localeCompare(b.district),
  },
  {
    dataIndex: "street",
    title: "Sk/Cadde",
  },
  {
    dataIndex: "buildingNo",
    title: "No",
  },
  {
    key: "ayarlar",
    dataIndex: "ayarlar",
    title: <></>,
    width: 30,
    render: (_, record) => (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1">Düzenle</Menu.Item>
            <Menu.Item key="2">Sil</Menu.Item>
          </Menu>
        }
      >
        <p style={{ cursor: "pointer" }}>
          <img
            src={moreIcon}
            style={{ height: `clamp(12px, 0.55rem + 0.25vw, 16px)` }}
            alt="more"
          />
        </p>
      </Dropdown>
    ),
  },
];

export const StoreManagement = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [originaStoreList, setOriginaStoreList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [clearFiltersClicked, setClearFiltersClicked] = useState(false);

  useEffect(() => {
    setStoreList(data.storeData);
    setOriginaStoreList(data.storeData);
  }, []);

  const excelExportConfig = {
    mappingFunction: ({
      id,
      company,
      phone,
      email,
      name,
      surname,
      city,
      district,
      street,
      buildingNo,
    }) => ({
      ID: id,
      Şirket: company,
      "Cep Telefonu": phone,
      "E-Posta": email,
      İsim: name,
      Soyisim: surname,
      İl: city,
      İlçe: district,
      "Sokak/Cadde": street,
      Numara: buildingNo,
    }),
  };

  return (
    <div className="outer__container">
      <Header title="Bayi Yönetimi" />
      <div className="storemanagement__content">
        <div className="page-blur01"></div>
        <div className="store__top">
          <div className="store__top-left"></div>
          <div className="store__top-right"></div>
        </div>
        <div className="drt__table" style={{ flex: 2 }}>
          <div className="table__header-container">
            <div className="table__header-left">
              <img src={DRT_Logo} alt="logo" className="table__big-icon" />
              <p className="table__header-title">Bayi Veri Sistemi</p>
            </div>
            <div className="table__header-middle">
              <div className="table__search-container">
                <Input
                  className="table__search-input"
                  prefix={<SearchOutlined />}
                  placeholder="Bayi Ara"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 1) {
                      setStoreList(
                        originaStoreList.filter((content) =>
                          filterContentOption(value, content)
                        )
                      );
                    } else {
                      setStoreList(originaStoreList);
                    }
                  }}
                  allowClear
                />
              </div>
            </div>
            <div className="table__header-right">
              <Tooltip title="Filtreleri Sıfırla">
                <button
                  onClick={() => {
                    setClearFiltersClicked(true);
                    setTimeout(() => {
                      setClearFiltersClicked(false);
                    }, 100);
                  }}
                  className="table__small-icon-container"
                >
                  <img
                    src={clearFilter}
                    alt="clearFilter"
                    className="table__small-icon"
                  />
                </button>
              </Tooltip>
              <Tooltip title="Excel'e Aktar">
                <button
                  onClick={() =>
                    exportToExcel(selectedItems, excelExportConfig)
                  }
                  className="table__small-icon-container"
                >
                  <img
                    src={exportUsers}
                    alt="exportUsers"
                    className="table__small-icon"
                  />
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="table__container">
            <CustomTable
              tableName={"StoreList"}
              columns={columnStores}
              dataSource={storeList}
              setSelectedItems={setSelectedItems}
              clearFiltersClicked={clearFiltersClicked}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
