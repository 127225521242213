import { useEffect, useState } from "react";
import "./ExpandedDrtID02.css";
import { tR } from "./functions";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const yieldGoalName = {
  maximumFertilizers: "Maksimum Verim",
  highFertilizers: "Yüksek Verim",
  optimumFertilizers: "Optimum Verim",
};

export const ExpandedDrtID02 = ({ record }) => {
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [programList, setProgramList] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const _selectedProgram = await tR(
          `/schedule-requests/single/${record.id}`,
          "GET"
        );

        setSelectedProgram(_selectedProgram);
      } finally {
        setIsLoading(false);
      }
    }

    setIsLoading(true);
    fetchData();
  }, [record.id]);

  const fertilizingSchedules = selectedProgram?.fertilizingSchedule;

  const groupedFertilizers = {};

  fertilizingSchedules?.forEach((fertilizer) => {
    const { month } = fertilizer;
    if (!groupedFertilizers[month]) {
      groupedFertilizers[month] = [];
    }
    groupedFertilizers[month].push(fertilizer);
  });

  useEffect(() => {
    getProgramList();
  }, []);

  const getProgramList = async () => {
    const _programList = await tR("/schedule-requests/program", "GET");
    setProgramList(_programList);
  };

  const getProgramName = (programKey) => {
    for (const plantKey in programList) {
      const plant = programList[plantKey];
      const program = plant.programs.find((p) => p.key === programKey);
      if (program) return program.name;
    }
  };

  const getPlantName = (programKey) => {
    for (const plantKey in programList) {
      const plant = programList[plantKey];
      const program = plant.programs.find((p) => p.key === programKey);
      if (program) return plant.plantName;
    }
    return "";
  };

  return (
    <div className="expandedid02__container">
      {isLoading ? (
        <div className="table__load-spinner">
          <Spin indicator={<LoadingOutlined style={{ color: "#AAFF00" }} />} />
        </div>
      ) : (
        <>
          <div className="expandedid02__info">
            {fertilizingSchedules && (
              <>
                <div className="expandedid02__info-row">
                  <p className="expandedid02__info-title">Bitki Adı</p>
                  <p className="expandedid02__info-text">
                    {getPlantName(selectedProgram.scheduleRequest.programName)}
                  </p>
                </div>
                <div className="expandedid02__info-row">
                  <p className="expandedid02__info-title">Sulama Tipi</p>
                  <p className="expandedid02__info-text">Damla Sulama</p>
                </div>
                <div className="expandedid02__info-row">
                  <p className="expandedid02__info-title">Program Türü</p>
                  <p className="expandedid02__info-text">
                    {getProgramName(
                      selectedProgram.scheduleRequest.programName
                    )}
                  </p>
                </div>
                <div className="expandedid02__info-row">
                  <p className="expandedid02__info-title">Verim Grubu</p>
                  <p className="expandedid02__info-text">
                    {
                      yieldGoalName[
                        selectedProgram.scheduleRequest.fertilizerSet
                      ]
                    }
                  </p>
                </div>
                <div className="expandedid02__info-row">
                  <p className="expandedid02__info-title">Program Uzunluğu</p>
                  <p className="expandedid02__info-text">
                    {selectedProgram.scheduleRequest.duration + " Ay"}
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="expandedid02__table">
            {Object.entries(groupedFertilizers).map(([month, fertilizers]) => (
              <div key={month} className="expandedid02__table-month-container">
                <p className="expandedid02__month-name">
                  {parseInt(month) + 1}. Ay
                </p>
                {fertilizers.map((fertilizer, index) => (
                  <p key={index}>{fertilizer.choosenFertilizer.name}</p>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
