import "./Sidebar.css";
import { Menu } from "antd";
import logo from "../assets/DRT_Logo.png";
import connect_logo from "../assets/DRT_Connect_Logo.png";
import dummyPP from "../assets/dummyPP.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { tR } from "./functions";

const items = [
  {
    key: 1,
    label: "Ana Sayfa",
    url: "/",
  },
  {
    key: 2,
    label: "İçerik Yönetimi",
    url: "/contentmanagement",
  },
  {
    key: 3,
    label: "DRT ID",
    url: "/drtid",
  },
  {
    key: 4,
    label: "Bayi Yönetimi",
    url: "/storemanagement",
  },
  {
    key: 5,
    label: "HRConnect",
    url: "/hrconnect",
  },
  {
    key: 6,
    label: "Yönetici Ayarları",
    url: "/adminsettings",
  },
];

export const Sidebar = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const userDetails = await tR("/users/me/details", "GET");

    setUser(userDetails);
  };

  return (
    <div className="sidebar__container">
      <div className="sidebar__content">
        <div className="sidebar__top">
          <img
            src={connect_logo}
            alt="DRT Connect Logo"
            className="sidebar__top-logo"
          />
          <div className="sidebar__profile-image-container">
            <div className="sidebar__profile-image-container2">
              {user?.profileImageObj && (
                <img
                  src={user.profileImageObj?.thumbnail6060}
                  alt="Profile"
                  className="sidebar__profile-image"
                />
              )}
            </div>
          </div>
          <p className="sidebar__name">{user?.name + " " + user?.surname}</p>
          <p className="sidebar__level">Super Admin</p>
        </div>
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          className="sidebar__menu"
        >
          {items.map((item) => (
            <Menu.Item className="sidebar__menu-item" key={item.key}>
              <Link to={item.url}>{item.label}</Link>
            </Menu.Item>
          ))}
        </Menu>
        <div className="sidebar__footer">
          <img src={logo} alt="DRT Logo" className="sidebar__footer-logo" />
          <p className="sidebar__footer-text">
            Doktor Tarsa Tarım Sanayi <br /> ve Ticaret A.Ş.
          </p>
          <p className="sidebar__footer-text">Tüm Hakları Saklıdır</p>
          <p className="sidebar__footer-text">v0.01</p>
        </div>
      </div>
    </div>
  );
};
