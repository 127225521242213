import axios from "axios";
import { Sector } from "recharts";
import * as XLSX from "xlsx";
export const url = "https://server11.mikroterratechnologies.com:5002";
// export const url = "http://192.168.1.104:5000";

export async function tU(endpoint, data) {
  try {
    const res = await axios({
      method: "POST",
      url: url + endpoint,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response.status === 401) {
      try {
        const res = await axios.post(url + "/auth/refresh-token", {
          refreshToken: localStorage.getItem("refreshToken"),
        });
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);

        const res2 = await axios({
          method: "POST",
          url: url + endpoint,
          data: data,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        return res2.data;
      } catch (err) {
        if (err.response.status === 401) {
          console.log("refresh token expired");
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/";
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/";
        }
      }
    } else {
    }
  }
}

export async function tR(endpoint, method, data) {
  try {
    const res = await axios({
      method: method,
      url: url + endpoint,
      data: data,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      timeout: 90000,
    });
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
    if (err.response.status === 401) {
      try {
        const res = await axios.post(url + "/auth/refresh-token", {
          refreshToken: localStorage.getItem("refreshToken"),
        });
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refreshToken", res.data.refreshToken);
        const res2 = await axios({
          method: method,
          url: url + endpoint,
          data: data,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        return res2.data;
      } catch (err) {
        if (err.response.status === 401) {
          console.log("refresh token expired");
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/";
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/";
        }
      }
    } else {
    }
  }
}
async function testRefreshToken() {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    console.log(refreshToken);
    if (refreshToken) {
      const res = await axios.post(url + "/auth/refresh-token", {
        refreshToken: refreshToken,
      });
      console.log(res.data);
      localStorage.setItem("token", res.data.accessToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      return true;
    } else {
      console.log("no refresh token");
    }
  } catch (err) {
    console.log(err);
  }
}

async function refreshAccessToken() {
  try {
    const res = await axios.post(url + "/auth/refresh-token", {
      refreshToken: localStorage.getItem("refreshToken"),
    });

    localStorage.setItem("token", res.data.accessToken);
    localStorage.setItem("refreshToken", res.data.refreshToken);

    console.error(`Token refreshed:`, res.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      console.error("Refresh token expired");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = "/";
    } else {
      console.error(`Unhandled error: `, err);
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = "/";
    }
  }
}

export function ShortenText(text, maxLength) {
  if (text?.length > maxLength) {
    if (text[maxLength - 1] === " ") {
      return text.substring(0, maxLength - 1) + "...";
    } else {
      return text.substring(0, maxLength) + "...";
    }
  }
  return text;
}

export const renderActiveShape = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
}) => {
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

export const renderCustomizedLabelPercentage = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + (radius - 5) * Math.cos((-midAngle * Math.PI) / 180);
  const y = cy + (radius - 5) * Math.sin((-midAngle * Math.PI) / 180);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      dominantBaseline="central"
      textAnchor={x > cx ? "start" : "end"}
      fontSize={`clamp(10px, 0rem + 1vw, 20px)`}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const renderCustomizedLabelValue = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + (radius - 5) * Math.cos((-midAngle * Math.PI) / 180);
  const y = cy + (radius - 5) * Math.sin((-midAngle * Math.PI) / 180);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      dominantBaseline="central"
      textAnchor={x > cx ? "start" : "end"}
      fontSize={`clamp(10px, 0rem + 1vw, 20px)`}
    >
      {value > 0 ? value : null}
    </text>
  );
};

export const pieColors = [
  "url(#gradient1)",
  `url(#gradient2)`,
  `url(#gradient3)`,
  `url(#gradient4)`,
  `url(#gradient5)`,
];

export const exportToExcel = (selectedItems, exportConfig) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  let exportData = [];

  if (selectedItems && selectedItems[0]) {
    exportData = selectedItems.map(exportConfig.mappingFunction);
  }

  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  const fileName = "data" + fileExtension;

  const a = document.createElement("a");
  a.href = URL.createObjectURL(data);
  a.download = fileName;

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
};

export const filterContentOption = (input, option) => {
  const searchFields = Object.keys(option).map((key) => key);

  return searchFields.some((field) =>
    String(option[field]).toLowerCase().includes(input.toLowerCase())
  );
};
