import {
  Dropdown,
  Input,
  Menu,
  Tooltip as AntdTooltip,
  Modal,
  Upload,
  Button,
  Image,
} from "antd";
import { CustomTable } from "../components/CustomTable";
import { Header } from "../components/Header";
import "./ContentManagement.css";
import { useEffect, useRef, useState } from "react";
import moreIcon from "../assets/moreIcon.png";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import academy from "../assets/academy.png";
import story from "../assets/story.png";
import news from "../assets/news.png";
import deleteIcon from "../assets/delete.png";
import clearFilter from "../assets/clearFilter.png";
import { tR, tU } from "../components/functions";
import dayjs from "dayjs";
import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import {
  renderActiveShape,
  renderCustomizedLabelValue,
  pieColors,
  filterContentOption,
} from "../components/functions";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";

export const ContentManagement = () => {
  const editorRef = useRef();
  const draftTimeoutRef = useRef(null);
  const [expandedRowKey, setExpandedRowKey] = useState(null);

  const [selectedItemsAcademy, setSelectedItemsAcademy] = useState([]);
  const [selectedItemsNews, setSelectedItemsNews] = useState([]);
  const [selectedItemsStory, setSelectedItemsStory] = useState([]);

  const [originalAcademyList, setOriginalAcademyList] = useState([]);
  const [originalNewsList, setOriginalNewsList] = useState([]);
  const [originalStoryList, setOriginalStoryList] = useState([]);

  const [academyList, setAcademyList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [storyList, setStoryList] = useState([]);

  const [activeIndex1, setActiveIndex1] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const [activeIndex3, setActiveIndex3] = useState(0);

  const [shownAcademyItems, setShownAcademyItems] = useState(null);
  const [shownNewsItems, setShownNewsItems] = useState(null);
  const [shownStoryItems, setShownStoryItems] = useState(null);

  const [shownModalItem, setShownModalItem] = useState(null);
  const [shownModalImage, setShownModalImage] = useState(null);
  const [currentEditorId, setCurrentEditorId] = useState(null);

  const [editingItem, setEditingItem] = useState(null);
  const [editTitle, setEditTitle] = useState(null);
  const [editContent, setEditContent] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [editReferences, setEditReferences] = useState(null);

  const [newTitle, setNewTitle] = useState(null);
  const [newContent, setNewContent] = useState(null);
  const [newImage, setNewImage] = useState(null);

  const [addAcademyModal, setAddAcademyModal] = useState(false);
  const [addNewsModal, setAddNewsModal] = useState(false);
  const [addStoryModal, setAddStoryModal] = useState(false);

  const [editAcademyModal, setEditAcademyModal] = useState(false);
  const [editNewsModal, setEditNewsModal] = useState(false);

  const [references, setReferences] = useState("");
  const [newReference, setNewReference] = useState("");

  useEffect(() => {
    getAcademyList();
    getStoryList();
    getNewsList();
  }, []);

  const getAcademyList = async () => {
    const _academyList = await tR("/academy", "GET");
    setAcademyList(_academyList);
    setOriginalAcademyList(_academyList);
  };

  const getStoryList = async () => {
    const _storyList = await tR("/stories", "GET");

    const updatedStoryList = _storyList?.map((story) => ({
      ...story,
      id: story.story.id,
    }));

    setStoryList(updatedStoryList);
    setOriginalStoryList(updatedStoryList);
  };

  const getNewsList = async () => {
    const _newsList = await tR("/news", "GET");
    setNewsList(_newsList);
    setOriginalNewsList(_newsList);
  };

  const columnsAcademy = [
    {
      key: "title",
      dataIndex: "title",
      title: "İçerik Adı",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      key: "author",
      dataIndex: "author",
      title: "Oluşturan",
      sorter: (a, b) => a.author.localeCompare(b.author),
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "Oluşturulma Tarihi",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (_, record) => dayjs(record.createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      key: "status",
      dataIndex: "status",
      title: "Yayın Durumu",
      filters: [
        { text: "Yayında", value: "published" },
        { text: "Yayında Değil", value: "unpublished" },
        { text: "Taslak", value: "draft" },
      ],
      render: (value) => {
        const statusMap = {
          published: "Yayında",
          unpublished: "Yayında Değil",
          draft: "Taslak",
        };
        const word = statusMap[value] || "Yayında";
        return <span>{word}</span>;
      },
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      key: "ayarlar",
      dataIndex: "ayarlar",
      title: <></>,
      width: 30,
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  setEditingItem(record);
                  setEditTitle(record.title);
                  setEditContent(record.content);
                  setEditImage(record.image);
                  setEditAcademyModal(true);
                  setCurrentEditorId(record.id);
                }}
                key="1"
              >
                Düzenle
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => {
                  tR(`/academy/one/${record.id}`, "DELETE").then(() => {
                    const updatedList = academyList.filter(
                      (content) => content.id !== record.id
                    );
                    setAcademyList(updatedList);
                    setOriginalAcademyList(updatedList);
                  });
                }}
              >
                Sil
              </Menu.Item>
              <Menu.Item
                key="3"
                style={{
                  display:
                    ["published", "unpublished"].includes(record.status) &&
                    record.title &&
                    record.content.length > 8 && // <p> ... </p> = 8
                    record.image
                      ? "block"
                      : "none",
                }}
                onClick={() => {
                  if (
                    ["published", "unpublished"].includes(record.status) &&
                    record.title &&
                    record.content.length > 8 && // <p> ... </p> = 8
                    record.image
                  ) {
                    const newStatus =
                      record.status === "published"
                        ? "unpublished"
                        : "published";
                    tR(`/academy/one/${record.id}`, "PATCH", {
                      status: newStatus,
                    }).then((res) => {
                      const updatedList = academyList.map((item) =>
                        item.id === record.id ? { ...item, ...res } : item
                      );
                      setAcademyList(updatedList);
                      setOriginalAcademyList(updatedList);
                    });
                  } else {
                    console.error("Cannot perform action. Conditions not met.");
                  }
                }}
              >
                {record.status === "published" ? "Yayından Al" : "Yayınla"}
              </Menu.Item>
              <Menu.Item
                key="4"
                style={{
                  display:
                    record.status === "draft" &&
                    record.title &&
                    record.content.length > 8 && // <p> ... </p> = 8
                    record.image
                      ? "block"
                      : "none",
                }}
                onClick={() => {
                  if (
                    record.status === "draft" &&
                    record.title &&
                    record.content.length > 8 && // <p> ... </p> = 8
                    record.image
                  ) {
                    tR(`/academy/one/${record.id}`, "PATCH", {
                      status: "published",
                    }).then((res) => {
                      const updatedList = academyList.map((item) =>
                        item.id === record.id ? { ...item, ...res } : item
                      );
                      setAcademyList(updatedList);
                      setOriginalAcademyList(updatedList);
                    });
                  } else {
                    console.error("Cannot publish. Draft is incomplete.");
                  }
                }}
              >
                Yayınla
              </Menu.Item>
            </Menu>
          }
        >
          <p style={{ cursor: "pointer" }}>
            <img
              src={moreIcon}
              style={{ height: `clamp(12px, 0.55rem + 0.25vw, 16px)` }}
              alt="more"
            />
          </p>
        </Dropdown>
      ),
    },
  ];

  const ciftciAkademiEtkilesim = [
    {
      name: "Aktif",
      value: academyList.filter((content) => content?.status === "published")
        .length,
    },
    {
      name: "İnaktif",
      value: academyList.filter((content) => content?.status !== "published")
        .length,
    },
  ];

  const columnsNews = [
    {
      key: "title",
      dataIndex: "title",
      title: "İçerik Adı",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      key: "author",
      dataIndex: "author",
      title: "Oluşturan",
      sorter: (a, b) => a.author.localeCompare(b.author),
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "Oluşturulma Tarihi",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (_, record) => dayjs(record.createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      key: "status",
      dataIndex: "status",
      title: "Yayın Durumu",
      filters: [
        { text: "Yayında", value: "published" },
        { text: "Yayında Değil", value: "unpublished" },
        { text: "Taslak", value: "draft" },
      ],
      render: (value) => {
        const statusMap = {
          published: "Yayında",
          unpublished: "Yayında Değil",
          draft: "Taslak",
        };
        const word = statusMap[value] || "Yayında";
        return <span>{word}</span>;
      },
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      key: "ayarlar",
      dataIndex: "ayarlar",
      title: <></>,
      width: 30,
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  setEditingItem(record);
                  setEditTitle(record.title);
                  setEditContent(record.content);
                  setEditImage(record.image);
                  setEditReferences(record.references);
                  setEditNewsModal(true);
                  setCurrentEditorId(record.id);
                }}
                key="1"
              >
                Düzenle
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => {
                  tR(`/news/one/${record.id}`, "DELETE").then(() => {
                    const updatedList = newsList.filter(
                      (content) => content.id !== record.id
                    );
                    setNewsList(updatedList);
                    setOriginalNewsList(updatedList);
                  });
                }}
              >
                Sil
              </Menu.Item>
              <Menu.Item
                key="3"
                style={{
                  display:
                    ["published", "unpublished"].includes(record.status) &&
                    record.title &&
                    record.content.length > 8 && // <p> ... </p> = 8
                    record.image
                      ? "block"
                      : "none",
                }}
                onClick={() => {
                  if (
                    ["published", "unpublished"].includes(record.status) &&
                    record.title &&
                    record.content.length > 8 && // <p> ... </p> = 8
                    record.image
                  ) {
                    const newStatus =
                      record.status === "published"
                        ? "unpublished"
                        : "published";
                    tR(`/news/one/${record.id}`, "PATCH", {
                      status: newStatus,
                    }).then((res) => {
                      const updatedList = newsList.map((item) =>
                        item.id === record.id ? { ...item, ...res } : item
                      );
                      setNewsList(updatedList);
                      setOriginalNewsList(updatedList);
                    });
                  } else {
                    console.error("Cannot perform action. Conditions not met.");
                  }
                }}
              >
                {record.status === "published" ? "Yayından Al" : "Yayınla"}
              </Menu.Item>
              <Menu.Item
                key="4"
                style={{
                  display:
                    record.status === "draft" &&
                    record.title &&
                    record.content.length > 8 && // <p> ... </p> = 8
                    record.image
                      ? "block"
                      : "none",
                }}
                onClick={() => {
                  if (
                    record.status === "draft" &&
                    record.title &&
                    record.content.length > 8 && // <p> ... </p> = 8
                    record.image
                  ) {
                    tR(`/news/one/${record.id}`, "PATCH", {
                      status: "published",
                    }).then((res) => {
                      const updatedList = newsList.map((item) =>
                        item.id === record.id ? { ...item, ...res } : item
                      );
                      setNewsList(updatedList);
                      setOriginalNewsList(updatedList);
                    });
                  } else {
                    console.error("Cannot publish. Draft is incomplete.");
                  }
                }}
              >
                Yayınla
              </Menu.Item>
            </Menu>
          }
        >
          <p style={{ cursor: "pointer" }}>
            <img
              src={moreIcon}
              style={{ height: `clamp(12px, 0.55rem + 0.25vw, 16px)` }}
              alt="more"
            />
          </p>
        </Dropdown>
      ),
    },
  ];

  const haberlerEtkilesim = [
    {
      name: "Aktif",
      value: newsList.filter((content) => content?.status === "published")
        .length,
    },
    {
      name: "İnaktif",
      value: newsList.filter((content) => content?.status !== "published")
        .length,
    },
  ];

  const columnsStory = [
    {
      key: "title",
      dataIndex: "title",
      title: "Başlık",
      sorter: (a, b) => a.story.title.localeCompare(b.story.title),
      render: (_, record) => <p>{record.story.title}</p>,
    },
    {
      key: "description",
      dataIndex: "description",
      title: "Açıklama",
      sorter: (a, b) => a.story.description.localeCompare(b.story.description),
      render: (_, record) => <p>{record.story.description}</p>,
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "Oluşturulma Tarihi",
      sorter: (a, b) => a.story.createdAt.localeCompare(b.story.createdAt),
      render: (_, record) =>
        dayjs(record.story.createdAt).format("DD/MM/YYYY HH:mm"),
    },
    {
      key: "image",
      dataIndex: "image",
      title: "Fotoğraf",
      render: (_, record) => (
        <a
          href={record.image.original}
          onClick={() => window.open(record.image.original, "_blank")}
          target="_blank"
          rel="noreferrer noopener"
          className="table__link"
        >
          {record.image.original}
        </a>
      ),
    },
    {
      key: "ayarlar",
      dataIndex: "ayarlar",
      title: <></>,
      width: 30,
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() => {
                  tR(`/stories/${record.id}`, "DELETE").then(() => {
                    const updatedList = storyList.filter(
                      (content) => content.story.id !== record.story.id
                    );
                    setStoryList(updatedList);
                    setOriginalStoryList(updatedList);
                  });
                }}
              >
                Sil
              </Menu.Item>
            </Menu>
          }
        >
          <p style={{ cursor: "pointer" }}>
            <img
              src={moreIcon}
              style={{ height: `clamp(12px, 0.55rem + 0.25vw, 16px)` }}
              alt="more"
            />
          </p>
        </Dropdown>
      ),
    },
  ];

  const storylerEtkilesim = [
    {
      name: "Aktif",
      value: storyList
        .sort((a, b) => dayjs(b.story.createdAt).diff(dayjs(a.story.createdAt)))
        .slice(0, 10).length,
    },
    {
      name: "İnaktif",
      value: storyList
        .sort((a, b) => dayjs(b.story.createdAt).diff(dayjs(a.story.createdAt)))
        .slice(10).length,
    },
  ];

  // GENERAL FUNCTIONS

  function uploadAdapter(loader) {
    return {
      upload: async () => {
        return await new Promise((resolve, reject) => {
          const formData = new FormData();

          loader.file.then((file) => {
            formData.append("file", file);

            try {
              tU(`/services/upload-image`, formData).then((res) => {
                resolve({ default: res.original.Location });
              });
            } catch (error) {
              console.error("error", error);
            }
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  function uploadNewImage(file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        tU(`/services/upload-image`, formData).then((res) => {
          resolve({ default: res.original.Location });
          setNewImage(res.original.Location);
          setShownModalImage(res.thumbnail256256.Location);
        });
      } catch (error) {
        console.error("error", error);
      }
    });
  }

  function uploadEditImage(file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        tU(`/services/upload-image`, formData).then((res) => {
          resolve({ default: res.original.Location });
          setEditImage(res.original.Location);
          setShownModalImage(res.thumbnail256256.Location);
        });
      } catch (error) {
        console.error("error", error);
      }
    });
  }

  const handleResetAll = () => {
    setNewTitle(null);
    setNewContent(null);
    setNewImage(null);

    setEditTitle(null);
    setEditContent(null);
    setEditImage(null);

    setCurrentEditorId(null);
    setShownModalImage(null);
    setShownModalItem(null);
    setEditingItem(null);

    setAddAcademyModal(false);
    setAddNewsModal(false);
    setAddStoryModal(false);

    setEditAcademyModal(false);
    setEditNewsModal(false);

    setReferences("");
    setNewReference("");

    clearTimeout(draftTimeoutRef.current);
  };

  useEffect(() => {
    return () => {
      clearTimeout(draftTimeoutRef.current);
    };
  }, []);

  // ACADEMY FUNCTIONS

  const handleAcademyPost = async () => {
    if (newTitle !== null && newContent !== null && newImage !== null) {
      if (currentEditorId) {
        await tR(`/academy/one/${currentEditorId}`, "DELETE");
        const updatedListWithoutDraft = academyList.filter(
          (item) => item.id !== currentEditorId
        );
        setAcademyList(updatedListWithoutDraft);
        setOriginalAcademyList(updatedListWithoutDraft);
      }

      const submitObject = {
        title: newTitle,
        content: newContent,
        image: newImage,
        status: "published",
      };

      try {
        await tR("/academy", "POST", submitObject).then((res) => {
          setAcademyList((prevList) => [...prevList, res]);
          setOriginalAcademyList((prevList) => [...prevList, res]);
        });

        handleResetAll();
      } catch (error) {
        console.error("Error posting content", error);
      }
    } else {
      console.error(
        "Title or content or image is null. Cannot submit. (handleAcademyPost)"
      );
    }
  };

  const handleAcademyEdit = async () => {
    if (editingItem) {
      if (
        editingItem.status === "published" &&
        (!editTitle || !editContent || !editImage)
      ) {
        console.error(
          "Cannot make title, content, or image empty for a published item."
        );
        return;
      }

      const submitObject = {
        title: newTitle ? newTitle : editTitle ? editTitle : undefined,
        content: newContent
          ? newContent
          : editContent
          ? editContent
          : undefined,
        image: newImage ? newImage : editImage ? editImage : undefined,
      };

      try {
        await tR(`/academy/one/${editingItem.id}`, "PATCH", submitObject);

        const updatedList = academyList.map((item) =>
          item.id === editingItem.id ? { ...item, ...submitObject } : item
        );

        setAcademyList(updatedList);
        setOriginalAcademyList(updatedList);
        handleResetAll();
      } catch (error) {
        console.error("Error editing academy item", error);
      }
    }
  };

  const handleAcademyDelete = async () => {
    const deletePromises = selectedItemsAcademy.map(async (item) => {
      return tR(`/academy/one/` + item.id, "DELETE");
    });

    try {
      const deletedResponses = await Promise.all(deletePromises);

      const updatedList = academyList.filter(
        (content) =>
          !selectedItemsAcademy.some((item) => item.id === content.id)
      );

      setAcademyList(updatedList);
      setOriginalAcademyList(updatedList);

      console.error("Items deleted successfully", deletedResponses);
    } catch (error) {
      console.error("Error deleting items", error);
    }
  };

  const handleAcademyCKEditorChange = (event, editor) => {
    const data = editor.getData();
    setNewContent(data);
    clearTimeout(draftTimeoutRef.current);

    draftTimeoutRef.current = setTimeout(() => {
      handleSaveAcademyDraft();
    }, 3000);
  };

  const handleSaveAcademyDraft = async () => {
    try {
      if (currentEditorId) {
        const submitObject = {
          title: newTitle ? newTitle : editTitle ? editTitle : undefined,
          content: newContent
            ? newContent
            : editContent
            ? editContent
            : undefined,
          image: newImage ? newImage : editImage ? editImage : undefined,
        };

        await tR(`/academy/one/${currentEditorId}`, "PATCH", submitObject).then(
          (res) => {
            const updatedList = academyList.map((item) =>
              item.id === currentEditorId ? { ...item, ...res } : item
            );
            setAcademyList(updatedList);
            setOriginalAcademyList(updatedList);
          }
        );
      } else {
        const submitObject = {
          title: newTitle || "",
          content: newContent || "",
          image: newImage || "",
          status: "draft",
        };

        await tR("/academy", "POST", submitObject).then((res) => {
          setAcademyList([...academyList, res]);
          setOriginalAcademyList([...academyList, res]);
          setCurrentEditorId(res.id);
        });
      }
    } catch (error) {
      console.error("Error saving draft", error);
    }
  };

  // NEWS FUNCTIONS

  const handleNewsPost = async () => {
    if (newTitle !== null && newContent !== null && newImage !== null) {
      if (currentEditorId) {
        await tR(`/news/one/${currentEditorId}`, "DELETE");
        const updatedListWithoutDraft = newsList.filter(
          (item) => item.id !== currentEditorId
        );
        setNewsList(updatedListWithoutDraft);
        setOriginalNewsList(updatedListWithoutDraft);
      }

      const submitObject = {
        title: newTitle,
        content: newContent,
        image: newImage,
        references: references,
        status: "published",
      };

      try {
        await tR("/news", "POST", submitObject).then((res) => {
          setNewsList((prevList) => [...prevList, res]);
          setOriginalNewsList((prevList) => [...prevList, res]);
        });

        handleResetAll();
      } catch (error) {
        console.error("Error posting content", error);
      }
    } else {
      console.error(
        "Title or content or image is null. Cannot submit. (handleNewsPost)"
      );
    }
  };

  const handleNewsEdit = async () => {
    if (editingItem) {
      if (
        editingItem.status === "published" &&
        (!editTitle || !editContent || !editImage)
      ) {
        console.error(
          "Cannot make title, content, or image empty for a published item."
        );
        return;
      }

      const submitObject = {
        title: newTitle ? newTitle : editTitle ? editTitle : undefined,
        content: newContent
          ? newContent
          : editContent
          ? editContent
          : undefined,
        image: newImage ? newImage : editImage ? editImage : undefined,
        references: references ? references : undefined,
      };

      try {
        await tR(`/news/one/${editingItem.id}`, "PATCH", submitObject);

        const updatedList = newsList.map((item) =>
          item.id === editingItem.id ? { ...item, ...submitObject } : item
        );

        setNewsList(updatedList);
        setOriginalNewsList(updatedList);
        handleResetAll();
      } catch (error) {
        console.error("Error editing news item", error);
      }
    }
  };

  const handleNewsDelete = async () => {
    const deletePromises = selectedItemsNews.map(async (item) => {
      return tR(`/news/one/` + item.id, "DELETE");
    });

    try {
      const deletedResponses = await Promise.all(deletePromises);

      const updatedList = newsList.filter(
        (content) => !selectedItemsNews.some((item) => item.id === content.id)
      );

      setNewsList(updatedList);
      setOriginalNewsList(updatedList);

      console.error("Items deleted successfully", deletedResponses);
    } catch (error) {
      console.error("Error deleting items", error);
    }
  };

  const handleAddReference = () => {
    if (references) {
      setReferences(`${references} | ${newReference}`);
    } else {
      setReferences(newReference);
    }
    setNewReference("");
  };

  const renderReferences = (previewRefs) => {
    if (references) {
      const referencesArray = references.split(/\s*\|\s*/); // | seperator

      return referencesArray.map((reference, index) => (
        <div key={index} className="modal__reference-container">
          <p className="modal__reference">{reference}</p>
        </div>
      ));
    } else if (previewRefs) {
      const referencesArray = previewRefs.split(/\s*\|\s*/); // | seperator

      return referencesArray.map((reference, index) => (
        <div
          key={index}
          className="modal__reference-container"
          style={{ textAlign: "right" }}
        >
          <p className="modal__reference" style={{ color: "black" }}>
            {reference}
          </p>
        </div>
      ));
    }
    return null;
  };

  const handleNewsCKEditorChange = (event, editor) => {
    const data = editor.getData();
    setNewContent(data);
    clearTimeout(draftTimeoutRef.current);

    draftTimeoutRef.current = setTimeout(() => {
      handleSaveNewsDraft();
    }, 3000);
  };

  const handleSaveNewsDraft = async () => {
    try {
      if (currentEditorId) {
        const submitObject = {
          title: newTitle ? newTitle : editTitle ? editTitle : undefined,
          content: newContent
            ? newContent
            : editContent
            ? editContent
            : undefined,
          image: newImage ? newImage : editImage ? editImage : undefined,
          references: references ? references : undefined,
        };

        await tR(`/news/one/${currentEditorId}`, "PATCH", submitObject).then(
          (res) => {
            const updatedList = newsList.map((item) =>
              item.id === currentEditorId ? { ...item, ...res } : item
            );
            setNewsList(updatedList);
            setOriginalNewsList(updatedList);
          }
        );
      } else {
        const submitObject = {
          title: newTitle || "",
          content: newContent || "",
          image: newImage || "",
          references: references || "",
          status: "draft",
        };

        await tR("/news", "POST", submitObject).then((res) => {
          setNewsList([...newsList, res]);
          setOriginalNewsList([...newsList, res]);
          setCurrentEditorId(res.id);
        });
      }
    } catch (error) {
      console.error("Error saving draft", error);
    }
  };

  // STORY FUNCTIONS

  function uploadNewStoryImage(file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        tU(`/services/upload-image`, formData).then((res) => {
          resolve({ default: res.original.Location });
          setNewImage(res.original.key);
          setShownModalImage(res.thumbnail256256.Location);
        });
      } catch (error) {
        console.error("error", error);
      }
    });
  }

  const handleStoryPost = async () => {
    if (newTitle && newContent && newImage) {
      const submitObject = {
        title: newTitle,
        description: newContent,
        image: newImage,
      };

      try {
        await tR("/stories", "POST", submitObject);
        getStoryList();
        handleResetAll();
      } catch (error) {
        console.error("Error posting content", error);
      }
    } else {
      console.error(
        "Title or content or image is null. Cannot submit. (handleStoryPost)"
      );
    }
  };

  const handleStoryDelete = async () => {
    const deletePromises = selectedItemsStory.map(async (item) => {
      return tR(`/stories/` + item.id, "DELETE");
    });

    try {
      const deletedResponses = await Promise.all(deletePromises);

      const updatedList = storyList.filter(
        (content) => !selectedItemsStory.some((item) => item.id === content.id)
      );

      setStoryList(updatedList);
      setOriginalStoryList(updatedList);

      console.error("Items deleted successfully", deletedResponses);
    } catch (error) {
      console.error("Error deleting items", error);
    }
  };

  return (
    <div className="outer__container">
      <Header title="İçerik Yönetimi" />
      <div className="contentmanagement__content">
        <div className="page-blur01"></div>
        <div className="content__row">
          <div className="drt__table" style={{ flex: 3 }}>
            <div className="table__header-container">
              <div className="table__header-left">
                <img src={academy} alt="logo" className="table__big-icon" />
                <p className="table__header-title">Çiftçi Akademi İçerikleri</p>
              </div>
              <div className="table__header-middle">
                <div className="table__search-container">
                  <Input
                    className="table__search-input"
                    prefix={<SearchOutlined />}
                    placeholder="İçerik Ara"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length > 1) {
                        setAcademyList(
                          originalAcademyList.filter((content) =>
                            filterContentOption(value, content)
                          )
                        );
                      } else {
                        setAcademyList(originalAcademyList);
                      }
                    }}
                    allowClear
                  />
                </div>
              </div>
              <div className="table__header-right">
                <button
                  onClick={handleAcademyDelete}
                  className="table__small-icon-container"
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    className="table__small-icon"
                  />
                </button>
                <button
                  onClick={() => setAddAcademyModal(true)}
                  className="header__button"
                >
                  + İçerik Ekle
                </button>

                {/* ADD NEW ITEM MODAL ACADEMY */}

                <Modal
                  maskClosable={false}
                  wrapClassName="content__addnewitem-modal"
                  width={1200}
                  centered
                  closable={false}
                  open={addAcademyModal}
                  onOk={handleAcademyPost}
                  okText="Ekle"
                  okButtonProps={{
                    style: { background: "green", border: "none" },
                  }}
                  onCancel={handleResetAll}
                  cancelText="Kapat"
                  cancelButtonProps={{
                    style: {
                      background: "red",
                      color: "white",
                      border: "none",
                    },
                  }}
                >
                  <Input
                    className="modal__title-input"
                    placeholder="İçerik Başlığı"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                  <Upload
                    name="file"
                    action={uploadNewImage}
                    headers={{ authorization: "authorization-text" }}
                    showUploadList={false}
                    maxCount={1}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ marginBottom: 8, borderRadius: 0 }}
                    >
                      Kapak Resmi
                    </Button>
                  </Upload>
                  <div className="modal__title-image-container">
                    {shownModalImage ? (
                      <Image
                        src={shownModalImage}
                        alt="title image"
                        className="modal__title-image"
                      />
                    ) : null}
                  </div>
                  <CKEditor
                    ref={editorRef}
                    disableWatchdog
                    editor={ClassicEditor}
                    config={{ language: "tr", extraPlugins: [uploadPlugin] }}
                    data={newContent || ""}
                    onChange={handleAcademyCKEditorChange}
                  />
                </Modal>

                {/* EDIT ITEM MODAL ACADEMY */}

                <Modal
                  maskClosable={false}
                  wrapClassName="content__edititem-modal"
                  width={1200}
                  centered
                  closable={false}
                  open={editAcademyModal}
                  onOk={handleAcademyEdit}
                  okText="Düzenle"
                  okButtonProps={{
                    style: { background: "green", border: "none" },
                  }}
                  onCancel={handleResetAll}
                  cancelText="Kapat"
                  cancelButtonProps={{
                    style: {
                      background: "red",
                      color: "white",
                      border: "none",
                    },
                  }}
                >
                  <Input
                    className="modal__title-input"
                    placeholder="İçerik Başlığı"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                  />
                  <Upload
                    name="file"
                    action={uploadEditImage}
                    headers={{ authorization: "authorization-text" }}
                    showUploadList={false}
                    maxCount={1}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ marginBottom: 8, borderRadius: 0 }}
                    >
                      Kapak Resmi
                    </Button>
                  </Upload>
                  <div className="modal__title-image-container">
                    {editImage ? (
                      <Image
                        src={editImage}
                        alt="title image"
                        className="modal__title-image"
                      />
                    ) : null}
                  </div>
                  <CKEditor
                    ref={editorRef}
                    disableWatchdog
                    editor={ClassicEditor}
                    config={{ language: "tr", extraPlugins: [uploadPlugin] }}
                    data={editContent || ""}
                    onChange={handleAcademyCKEditorChange}
                  />
                </Modal>

                {/* PREVIEW ITEM MODAL ACADEMY */}

                <Modal
                  wrapClassName="content__previewItem-modal"
                  width={1200}
                  centered
                  open={shownModalItem}
                  okButtonProps={{ style: { display: "none" } }}
                  cancelButtonProps={{ style: { display: "none" } }}
                  onCancel={() => setShownModalItem(null)}
                >
                  <div className="content__preview-top-container">
                    <div className="content__preview-title">
                      {shownModalItem?.title}
                    </div>
                    <img
                      className="content__preview-image"
                      src={shownModalItem?.image}
                      alt="title img"
                    />
                  </div>
                  <div
                    className="content__preview-container"
                    dangerouslySetInnerHTML={{
                      __html: shownModalItem?.content,
                    }}
                  />
                </Modal>
              </div>
            </div>
            <div className="table__container">
              <CustomTable
                tableName={"AcademyList"}
                columns={columnsAcademy}
                expandable={{
                  expandedRowRender: (record) => <></>,
                  expandedRowKeys: [expandedRowKey],
                  onExpand: (expanded, record) => setShownModalItem(record),
                  rowExpandable: (record) => record.expandedContent !== "",
                  expandIcon: ({ expanded, onExpand, record }) =>
                    record.expandedContent !== "" ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          onExpand(record, e);
                        }}
                        className="content__table-expand-icon-container"
                      >
                        <img
                          src={require("../assets/preview01.png")}
                          alt="expand-icon"
                          className="content__table-expand-icon"
                        />
                      </div>
                    ) : null,
                }}
                dataSource={shownAcademyItems ? shownAcademyItems : academyList}
                setSelectedItems={setSelectedItemsAcademy}
              />
            </div>
          </div>
          <div className="drt__table" style={{ overflow: "hidden" }}>
            <div className="table__header-container">
              <div className="table__header-left">
                <img src={academy} alt="logo" className="table__big-icon" />
                <p className="table__header-title">Çifçti Akademi Analizi</p>
              </div>
              <div className="table__header-right">
                <AntdTooltip title="Filtreyi Sıfırla">
                  <button
                    className="table__small-icon-container"
                    onClick={() => {
                      setAcademyList(originalAcademyList);
                      setShownAcademyItems(null);
                    }}
                  >
                    <img
                      src={clearFilter}
                      alt="clearFilter"
                      className="table__small-icon"
                    />
                  </button>
                </AntdTooltip>
              </div>
            </div>
            <ResponsiveContainer height="100%" width="100%">
              <PieChart>
                <Pie
                  onClick={(entry, index) => {
                    const clickedStatus = entry.name;

                    const filteredItems = academyList.filter(
                      (content) =>
                        (clickedStatus === "Aktif" &&
                          content?.status === "published") ||
                        (clickedStatus === "İnaktif" &&
                          (content?.status === "unpublished" ||
                            content?.status === "draft"))
                    );

                    setShownAcademyItems(filteredItems);
                    setActiveIndex1(index);
                  }}
                  activeIndex={activeIndex1}
                  activeShape={renderActiveShape}
                  onMouseEnter={(entry, index) => {
                    setActiveIndex1(index);
                  }}
                  isAnimationActive={true}
                  dataKey="value"
                  data={ciftciAkademiEtkilesim}
                  fill="#8884d8"
                  labelLine={false}
                  label={(value) => renderCustomizedLabelValue(value)}
                >
                  {ciftciAkademiEtkilesim.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieColors[index % pieColors.length]}
                    />
                  ))}
                </Pie>
                <defs>
                  <linearGradient
                    id="gradient1"
                    x1="25%"
                    y1="50%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#13c5a9 " />
                    <stop offset="100%" stopColor="#154a4a " />
                  </linearGradient>
                  <linearGradient
                    id="gradient2"
                    x1="25%"
                    y1="25%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#ffaa00" />
                    <stop offset="100%" stopColor="#e3c082" />
                  </linearGradient>
                </defs>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="content__row">
          <div className="drt__table" style={{ flex: 3 }}>
            <div className="table__header-container">
              <div className="table__header-left">
                <img src={news} alt="logo" className="table__big-icon" />
                <p className="table__header-title">Haberler</p>
              </div>
              <div className="table__header-middle">
                <div className="table__search-container">
                  <Input
                    className="table__search-input"
                    prefix={<SearchOutlined />}
                    placeholder="İçerik Ara"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length > 1) {
                        setNewsList(
                          originalNewsList.filter((content) =>
                            filterContentOption(value, content)
                          )
                        );
                      } else {
                        setNewsList(originalNewsList);
                      }
                    }}
                    allowClear
                  />
                </div>
              </div>
              <div className="table__header-right">
                <button
                  onClick={handleNewsDelete}
                  className="table__small-icon-container"
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    className="table__small-icon"
                  />
                </button>
                <button
                  onClick={() => setAddNewsModal(true)}
                  className="header__button"
                >
                  + İçerik Ekle
                </button>

                {/* ADD NEW ITEM MODAL NEWS */}

                <Modal
                  maskClosable={false}
                  wrapClassName="content__addnewitem-modal"
                  width={1200}
                  centered
                  closable={false}
                  open={addNewsModal}
                  onOk={handleNewsPost}
                  okText="Ekle"
                  okButtonProps={{
                    style: { background: "green", border: "none" },
                  }}
                  onCancel={handleResetAll}
                  cancelText="Kapat"
                  cancelButtonProps={{
                    style: {
                      background: "red",
                      color: "white",
                      border: "none",
                    },
                  }}
                >
                  <Input
                    className="modal__title-input"
                    placeholder="İçerik Başlığı"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                  <Upload
                    name="file"
                    action={uploadNewImage}
                    headers={{ authorization: "authorization-text" }}
                    showUploadList={false}
                    maxCount={1}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ borderRadius: 0 }}
                    >
                      Kapak Resmi
                    </Button>
                  </Upload>
                  <div className="modal__title-image-container">
                    {shownModalImage ? (
                      <Image
                        src={shownModalImage}
                        alt="title image"
                        className="modal__title-image"
                        style={{ marginTop: 8 }}
                      />
                    ) : null}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <Input
                        className="modal__title-input"
                        placeholder="Referansları giriniz"
                        value={newReference}
                        onChange={(e) => setNewReference(e.target.value)}
                      />
                      <button
                        className="modal__top-button"
                        onClick={handleAddReference}
                      >
                        Ekle
                      </button>
                      <button
                        className="modal__top-button"
                        onClick={() => {
                          setReferences("");
                        }}
                      >
                        Sıfırla
                      </button>
                    </div>
                    <div className="newsdetail__references-container">
                      {renderReferences()}
                    </div>
                  </div>
                  <CKEditor
                    ref={editorRef}
                    disableWatchdog
                    editor={ClassicEditor}
                    config={{ language: "tr", extraPlugins: [uploadPlugin] }}
                    data={newContent || ""}
                    onChange={handleNewsCKEditorChange}
                  />
                </Modal>

                {/* EDIT ITEM MODAL NEWS */}

                <Modal
                  maskClosable={false}
                  wrapClassName="content__edititem-modal"
                  width={1200}
                  centered
                  closable={false}
                  open={editNewsModal}
                  onOk={handleNewsEdit}
                  okText="Düzenle"
                  okButtonProps={{
                    style: { background: "green", border: "none" },
                  }}
                  onCancel={handleResetAll}
                  cancelText="Kapat"
                  cancelButtonProps={{
                    style: {
                      background: "red",
                      color: "white",
                      border: "none",
                    },
                  }}
                >
                  <Input
                    className="modal__title-input"
                    placeholder="İçerik Başlığı"
                    value={editTitle}
                    onChange={(e) => setEditTitle(e.target.value)}
                  />
                  <Upload
                    name="file"
                    action={uploadEditImage}
                    headers={{ authorization: "authorization-text" }}
                    showUploadList={false}
                    maxCount={1}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ borderRadius: 0 }}
                    >
                      Kapak Resmi
                    </Button>
                  </Upload>
                  <div className="modal__title-image-container">
                    {editImage ? (
                      <Image
                        src={editImage}
                        alt="title image"
                        className="modal__title-image"
                        style={{ marginTop: 8 }}
                      />
                    ) : null}
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <Input
                        className="modal__title-input"
                        placeholder="Referansları giriniz"
                        value={newReference}
                        onChange={(e) => setNewReference(e.target.value)}
                      />
                      <button
                        className="modal__top-button"
                        onClick={handleAddReference}
                      >
                        Ekle
                      </button>
                      <button
                        className="modal__top-button"
                        onClick={() => setEditReferences("")}
                      >
                        Sıfırla
                      </button>
                    </div>
                    <div>{renderReferences(editReferences)}</div>
                  </div>
                  <CKEditor
                    ref={editorRef}
                    disableWatchdog
                    editor={ClassicEditor}
                    config={{ language: "tr", extraPlugins: [uploadPlugin] }}
                    data={editContent || ""}
                    onChange={handleNewsCKEditorChange}
                  />
                </Modal>

                {/* PREVIEW ITEM MODAL NEWS */}

                <Modal
                  wrapClassName="content__previewItem-modal"
                  width={1200}
                  centered
                  open={shownModalItem}
                  okButtonProps={{ style: { display: "none" } }}
                  cancelButtonProps={{ style: { display: "none" } }}
                  onCancel={() => setShownModalItem(null)}
                >
                  <div className="content__preview-top-container">
                    <div className="content__preview-title">
                      {shownModalItem?.title}
                    </div>
                    <img
                      className="content__preview-image"
                      src={shownModalItem?.image}
                      alt="title img"
                    />
                  </div>
                  <div
                    className="content__preview-container"
                    dangerouslySetInnerHTML={{
                      __html: shownModalItem?.content,
                    }}
                  />
                  {shownModalItem?.references ? (
                    <div>
                      <p className="modal__references-title">REFERANSLAR</p>
                      {renderReferences(shownModalItem?.references)}
                    </div>
                  ) : null}
                </Modal>
              </div>
            </div>
            <div className="table__container">
              <CustomTable
                tableName={"NewsList"}
                columns={columnsNews}
                expandable={{
                  expandedRowRender: (record) => <></>,
                  expandedRowKeys: [expandedRowKey],
                  onExpand: (expanded, record) => setShownModalItem(record),
                  rowExpandable: (record) => record.expandedContent !== "",
                  expandIcon: ({ expanded, onExpand, record }) =>
                    record.expandedContent !== "" ? (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          onExpand(record, e);
                        }}
                        className="content__table-expand-icon-container"
                      >
                        <img
                          src={require("../assets/preview01.png")}
                          alt="expand-icon"
                          className="content__table-expand-icon"
                        />
                      </div>
                    ) : null,
                }}
                dataSource={shownNewsItems ? shownNewsItems : newsList}
                setSelectedItems={setSelectedItemsNews}
              />
            </div>
          </div>
          <div className="drt__table" style={{ overflow: "hidden" }}>
            <div className="table__header-container">
              <div className="table__header-left">
                <img src={news} alt="logo" className="table__big-icon" />
                <p className="table__header-title">Haberler Analizi</p>
              </div>
              <div className="table__header-right">
                <AntdTooltip title="Filtreyi Sıfırla">
                  <button
                    className="table__small-icon-container"
                    onClick={() => {
                      setNewsList(originalNewsList);
                      setShownNewsItems(null);
                    }}
                  >
                    <img
                      src={clearFilter}
                      alt="clearFilter"
                      className="table__small-icon"
                    />
                  </button>
                </AntdTooltip>
              </div>
            </div>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  onClick={(entry, index) => {
                    const clickedStatus = entry.name;

                    const filteredItems = newsList.filter(
                      (content) =>
                        (clickedStatus === "Aktif" &&
                          content?.status === "published") ||
                        (clickedStatus === "İnaktif" &&
                          (content?.status === "unpublished" ||
                            content?.status === "draft"))
                    );

                    setShownNewsItems(filteredItems);
                    setActiveIndex2(index);
                  }}
                  activeIndex={activeIndex2}
                  activeShape={renderActiveShape}
                  onMouseEnter={(entry, index) => {
                    setActiveIndex2(index);
                  }}
                  isAnimationActive={true}
                  dataKey="value"
                  data={haberlerEtkilesim}
                  fill="#8884d8"
                  labelLine={false}
                  label={(value) => renderCustomizedLabelValue(value)}
                >
                  {haberlerEtkilesim.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieColors[index % pieColors.length]}
                    />
                  ))}
                </Pie>
                <defs>
                  <linearGradient
                    id="gradient1"
                    x1="25%"
                    y1="50%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#13c5a9 " />
                    <stop offset="100%" stopColor="#154a4a " />
                  </linearGradient>
                  <linearGradient
                    id="gradient2"
                    x1="25%"
                    y1="25%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#ffaa00" />
                    <stop offset="100%" stopColor="#e3c082" />
                  </linearGradient>
                </defs>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="content__row">
          <div className="drt__table" style={{ flex: 3 }}>
            <div className="table__header-container">
              <div className="table__header-left">
                <img src={story} alt="logo" className="table__big-icon" />
                <p className="table__header-title">Story İçerikleri</p>
              </div>
              <div className="table__header-middle">
                <div className="table__search-container">
                  <Input
                    className="table__search-input"
                    prefix={<SearchOutlined />}
                    placeholder="İçerik Ara"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length > 1) {
                        setStoryList(
                          originalStoryList.filter((content) =>
                            filterContentOption(value, content)
                          )
                        );
                      } else {
                        setStoryList(originalStoryList);
                      }
                    }}
                    allowClear
                  />
                </div>
              </div>
              <div className="table__header-right">
                <button
                  onClick={handleStoryDelete}
                  className="table__small-icon-container"
                >
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    className="table__small-icon"
                  />
                </button>
                <button
                  className="header__button"
                  onClick={() => setAddStoryModal(true)}
                >
                  + İçerik Ekle
                </button>

                {/* ADD NEW STORY MODAL */}

                <Modal
                  maskClosable={false}
                  wrapClassName="content__addnewitem-modal"
                  width={1200}
                  centered
                  closable={false}
                  open={addStoryModal}
                  onOk={handleStoryPost}
                  okText="Ekle"
                  okButtonProps={{
                    style: { background: "green", border: "none" },
                  }}
                  onCancel={handleResetAll}
                  cancelText="Kapat"
                  cancelButtonProps={{
                    style: {
                      background: "red",
                      color: "white",
                      border: "none",
                    },
                  }}
                >
                  <Input
                    className="modal__title-input"
                    placeholder="Story Başlığı"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                  <Upload
                    name="file"
                    action={uploadNewStoryImage}
                    headers={{ authorization: "authorization-text" }}
                    showUploadList={false}
                    maxCount={1}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ marginBottom: 8, borderRadius: 0 }}
                    >
                      Story Görseli
                    </Button>
                  </Upload>
                  <div className="modal__title-image-container">
                    {shownModalImage ? (
                      <Image
                        src={shownModalImage}
                        alt="title image"
                        className="modal__title-image"
                      />
                    ) : null}
                  </div>
                  <Input
                    className="modal__title-input"
                    placeholder="Story Açıklaması"
                    value={newContent}
                    onChange={(e) => setNewContent(e.target.value)}
                  />
                </Modal>
              </div>
            </div>
            <div className="table__container">
              <CustomTable
                tableName={"StoryList"}
                columns={columnsStory}
                dataSource={shownStoryItems ? shownStoryItems : storyList}
                setSelectedItems={setSelectedItemsStory}
              />
            </div>
          </div>
          <div className="drt__table" style={{ overflow: "hidden" }}>
            <div className="table__header-container">
              <div className="table__header-left">
                <img src={story} alt="logo" className="table__big-icon" />
                <p className="table__header-title">Story Analizi</p>
              </div>
              <div className="table__header-right">
                <AntdTooltip title="Filtreyi Sıfırla">
                  <button
                    className="table__small-icon-container"
                    onClick={() => {
                      setStoryList(originalStoryList);
                      setShownStoryItems(null);
                    }}
                  >
                    <img
                      src={clearFilter}
                      alt="clearFilter"
                      className="table__small-icon"
                    />
                  </button>
                </AntdTooltip>
              </div>
            </div>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  onClick={(entry, index) => {
                    const clickedStatus = entry.name;

                    const filteredItems =
                      clickedStatus === "Aktif"
                        ? storyList
                            .sort((a, b) =>
                              dayjs(b.story.createdAt).diff(
                                dayjs(a.story.createdAt)
                              )
                            )
                            .slice(0, 10)
                        : storyList
                            .sort((a, b) =>
                              dayjs(b.story.createdAt).diff(
                                dayjs(a.story.createdAt)
                              )
                            )
                            .slice(10);

                    setShownStoryItems(filteredItems);
                    setActiveIndex3(index);
                  }}
                  activeIndex={activeIndex3}
                  activeShape={renderActiveShape}
                  onMouseEnter={(_, index) => setActiveIndex3(index)}
                  isAnimationActive={true}
                  dataKey="value"
                  data={storylerEtkilesim}
                  fill="#8884d8"
                  labelLine={false}
                  label={renderCustomizedLabelValue}
                >
                  {storylerEtkilesim.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={pieColors[index % pieColors.length]}
                    />
                  ))}
                </Pie>
                <defs>
                  <linearGradient
                    id="gradient1"
                    x1="25%"
                    y1="50%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#13c5a9 " />
                    <stop offset="100%" stopColor="#154a4a " />
                  </linearGradient>
                  <linearGradient
                    id="gradient2"
                    x1="25%"
                    y1="25%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#ffaa00" />
                    <stop offset="100%" stopColor="#e3c082" />
                  </linearGradient>
                </defs>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};
