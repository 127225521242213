import { useEffect, useState, useRef } from "react";
import { Input, Button, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const CustomTable = ({
  setSelectedItems,
  expandable,
  tableName,
  columns,
  dataSource,
  clearFiltersClicked,
}) => {
  const searchInput = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tableKey, setTableKey] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (clearFiltersClicked) {
      clearAllFilers();
    }
  }, [clearFiltersClicked]);

  const clearAllFilers = () => {
    setTableKey((tableKey) => tableKey + 1);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{ padding: 8, backgroundColor: "#444" }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={"Aranacak kelimeyi giriniz"}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ fontSize: 10, backgroundColor: "#4CBB17", border: "none" }}
          >
            Ara
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ fontSize: 10, border: "none" }}
          >
            Sıfırla
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "rgb(0, 255, 0)" : "#222" }} />
    ),
    onFilter: (value, record) => {
      const valueToSearch = dataIndex
        .split(".")
        .reduce((obj, key) => obj?.[key], record);
      return valueToSearch
        ?.toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const updatedColumns = columns.map((column) => {
    if (
      tableName === "UserList" &&
      [
        "id",
        "name",
        "surname",
        "phone",
        "email",
        "company",
        "City",
        "District",
        "Province",
      ].includes(column.dataIndex)
    ) {
      return {
        ...column,
        ...getColumnSearchProps(column.dataIndex),
      };
    }

    if (
      tableName === "StoreList" &&
      [
        "id",
        "company",
        "phone",
        "phoneSabit",
        "email",
        "name",
        "surname",
        "city",
        "district",
        "street",
        "buildingNo",
      ].includes(column.dataIndex)
    ) {
      return {
        ...column,
        ...getColumnSearchProps(column.dataIndex),
      };
    }

    if (
      tableName === "ExpandedID01Table" &&
      [
        "plantName",
        "programName",
        "irrigationType",
        "fertilizerSet",
        "duration",
      ].includes(column.dataIndex)
    ) {
      return {
        ...column,
        ...getColumnSearchProps(column.dataIndex),
      };
    }

    // Alttaki varken Home sayfasından Link ile gidilmiyor.
    // if (tableName === "MessagesList" && ["author"].includes(column.dataIndex)) {
    //   return {
    //     ...column,
    //     ...getColumnSearchProps(column.dataIndex),
    //   };
    // }

    return column;
  });

  const rowSelection = {
    type: "checkbox",
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    getCheckboxProps: (record) => ({
      key: record.id,
    }),
  };

  return (
    <Table
      locale={{
        triggerAsc: "Sıralamayı artan olarak değiştir",
        triggerDesc: "Sıralamayı azalan olarak değiştir",
        cancelSort: "Sıralamayı kaldır",
        emptyText: (
          <div
            style={{
              textAlign: "center",
              padding: 10,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <img
              src={require("../assets/danger.png")}
              alt="danger"
              style={{
                width: 40,
                height: 40,
                opacity: 0.8,
                filter: "invert(1)",
              }}
            />
            <p style={{ color: "white" }}>Veri Bulunamadı.</p>
          </div>
        ),
      }}
      key={tableKey}
      rowKey={(record) => record.id}
      size="small"
      pagination={false}
      tableLayout="auto"
      columns={updatedColumns}
      dataSource={dataSource}
      rowSelection={rowSelection}
      bordered={true}
      style={{ cursor: "default" }}
      loading={isLoading ? true : false}
      expandable={expandable}
    />
  );
};
