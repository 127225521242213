import "./Login.css";
import { Form, Input } from "antd";
import logo from "../assets/DRT_Logo.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export const Login = ({ handleLogin }) => {
  useEffect(() => {
    var loginForm = document.getElementById("login-form");
    if (loginForm) {
      loginForm.addEventListener("keypress", function (e) {
        var key = e.which || e.keyCode;
        if (key === 13) {
          submitForm(e);
        }
      });
    }

    return () => {
      if (loginForm) {
        loginForm.removeEventListener("keypress", function (e) {
          var key = e.which || e.keyCode;
          if (key === 13) {
            submitForm(e);
          }
        });
      }
    };
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    const loginPhone = document.getElementById("login-phone");
    const loginPassword = document.getElementById("login-password");

    const submitObject = {
      loginPhone: loginPhone.value,
      loginPassword: loginPassword.value,
    };

    handleLogin(submitObject);
  };

  return (
    <div className="login__container">
      <div className="page-blur02"></div>
      <div className="login__content">
        <div></div>
        <div className="login__logo-container">
          <img src={logo} alt="drt-logo" className="login__logo" />
        </div>
        <div className="login__login-form-container">
          <Form
            name="normal_login"
            className="login__login-form"
            onFinish={submitForm}
          >
            <Form.Item
              className="login__form-item"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Lütfen cep telefonunuzu giriniz.",
                },
              ]}
            >
              <Input
                id="login-phone"
                className="login__form-input"
                type="text"
                placeholder="Şirket E-Postası"
              />
            </Form.Item>
            <Form.Item
              className="login__form-item"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Lütfen şifrenizi giriniz.",
                },
              ]}
            >
              <Input
                id="login-password"
                className="login__form-input"
                type="password"
                placeholder="Şifreniz"
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item>
              <Link to="/">
                <button
                  onClick={submitForm}
                  className="login__form-login-button"
                  type="submit"
                >
                  Giriş Yap
                </button>
              </Link>
            </Form.Item>
          </Form>
        </div>
        <div className="login__footer">
          <p className="login__footer-text">
            Doktor Tarsa Tarım Sanayi ve Ticaret A.Ş. <br /> Tüm Hakları
            Saklıdır. <br /> 2024
          </p>
        </div>
      </div>
    </div>
  );
};
