import { Dropdown, Input, Menu, Tabs, Tooltip } from "antd";
import { Header } from "../components/Header";
import "./StoreManagement.css";
import { CustomTable } from "../components/CustomTable";
import DRT_Logo from "../assets/DRT_Logo.png";
import exportUsers from "../assets/exportUsers.png";
import moreIcon from "../assets/moreIcon.png";
import clearFilter from "../assets/clearFilter.png";
import data from "../data.json";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  exportToExcel,
  filterContentOption,
  tR,
} from "../components/functions";

import Dayjs from "dayjs";
import { type } from "@testing-library/user-event/dist/type";
import SurveyPage from "../SurveyPage";

const columnHR = [
  {
    key: "name",
    dataIndex: "name",
    title: "Ad",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    key: "surName",
    dataIndex: "surName",
    title: "Soyad",
    sorter: (a, b) => a.surName.localeCompare(b.surName),
  },
  {
    key: "birthday",
    dataIndex: "birthday",
    title: "Doğum Tarihi",
    sorter: (a, b) => a.birthday.localeCompare(b.birthday),
  },
  {
    title: "Yaş",
    render: (record) => {
      const birthDate = Dayjs(record.birthday, "DD.MM.YYYY");
      const now = Dayjs();
      const age = now.diff(birthDate, "year");
      return age;
    },
    sorter: (a, b) => {
      const birthDateA = Dayjs(a.birthday, "DD.MM.YYYY");
      const birthDateB = Dayjs(b.birthday, "DD.MM.YYYY");
      return birthDateA.diff(birthDateB, "year");
    },
  },
  {
    key: "phoneNumber",
    dataIndex: "phoneNumber",
    title: "Telefon",
    sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
  },
  {
    key: "email",
    dataIndex: "email",
    title: "E-Posta",
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    key: "gender",
    dataIndex: "gender",
    title: "Cinsiyet",
    sorter: (a, b) => a.gender.localeCompare(b.gender),
  },
  {
    key: "nationality",
    dataIndex: "nationality",
    title: "Uyruk",
    sorter: (a, b) => a.nationality.localeCompare(b.nationality),
  },

  {
    key: "schoolCity",
    dataIndex: "schoolCity",
    title: "Okul İli",
    sorter: (a, b) => a.schoolCity.localeCompare(b.schoolCity),
  },
  {
    key: "schoolName",
    dataIndex: "schoolName",
    title: "Okul Adı",
    sorter: (a, b) => a.schoolName.localeCompare(b.schoolName),
  },
  {
    key: "department",
    dataIndex: "department",
    title: "Bölüm",
    sorter: (a, b) => a.department.localeCompare(b.department),
  },
  {
    key: "clas",
    dataIndex: "clas",
    title: "Sınıf",
    sorter: (a, b) => a.clas.localeCompare(b.clas),
  },
  {
    key: "workxp",
    dataIndex: "workxp",
    title: "İş Tecrübesi",
    sorter: (a, b) => a.workxp.localeCompare(b.workxp),
  },
  {
    key: "position",
    dataIndex: "position",
    title: "Pozisyon",
    sorter: (a, b) => a.position.localeCompare(b.position),
  },
  {
    key: "year",
    dataIndex: "year",
    title: "Yıl",
    sorter: (a, b) => a.year.localeCompare(b.year),
  },
  {
    key: "chronicDisease",
    dataIndex: "chronicDisease",
    title: "Kronik Hastalık",
    sorter: (a, b) => a.chronicDisease.localeCompare(b.chronicDisease),
  },
  {
    key: "disability",
    dataIndex: "disability",
    title: "Engel Durumu",
    sorter: (a, b) => a.disability.localeCompare(b.disability),
  },
  {
    key: "isSmoking",
    dataIndex: "isSmoking",
    title: "Sigara Kullanımı",
    render: (record) => {
      if (record === undefined) {
        return <span style={{ color: "#000000" }}>Bilinmiyor</span>;
      } else if (record === true) {
        return <span style={{ color: "#FF0000" }}>İçiyor</span>;
      } else {
        return <span style={{ color: "#00FF00" }}>İçmiyor</span>;
      }
    },
    sorter: (a, b) => {
      if (a.isSmoking === undefined && b.isSmoking === undefined) return 0;
      if (a.isSmoking === undefined) return 1;
      if (b.isSmoking === undefined) return -1;
      if (a.isSmoking === b.isSmoking) return 0;
      if (a.isSmoking === true) return 1;
      if (b.isSmoking === true) return -1;
      return 0;
    },
  },

  //{
  //  key: "ayarlar",
  //  dataIndex: "ayarlar",
  //  title: <></>,
  //  width: 30,
  //  render: (_, record) => (
  //    <Dropdown
  //      overlay={
  //        <Menu>
  //          <Menu.Item key="1">Düzenle</Menu.Item>
  //          <Menu.Item key="2">Sil</Menu.Item>
  //        </Menu>
  //      }
  //    >
  //      <p style={{ cursor: "pointer" }}>
  //        <img
  //          src={moreIcon}
  //          style={{ height: `clamp(12px, 0.55rem + 0.25vw, 16px)` }}
  //          alt="more"
  //        />
  //      </p>
  //    </Dropdown>
  //  ),
  //},
];

export const HrConnect = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [originaStoreList, setOriginaStoreList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [clearFiltersClicked, setClearFiltersClicked] = useState(false);

  const [candidateList, setCandidateList] = useState([]);

  useEffect(() => {
    getAllCandidates();
  }, []);

  const getAllCandidates = async () => {
    tR("/humanresources", "GET").then((response) => {
      setOriginaStoreList(response);
      setStoreList(response);

      const _candidateList = response.map((candidate) => {
        let smoking;

        if (typeof candidate.healthInfo?.isSmoker === "boolean") {
          smoking = candidate.healthInfo?.isSmoker;
        } else {
          smoking = undefined;
        }

        return {
          ...candidate,
          key: candidate.id,
          schoolCity: candidate.educationInfo?.[0]?.schoolCity || "-",
          schoolName: candidate.educationInfo?.[0]?.schoolName || "-",
          department: candidate.educationInfo?.[0]?.department || "-",
          clas: candidate.educationInfo?.[0]?.class || "-",
          lng: candidate.languages?.[0]?.language || "-",
          read: candidate.languages?.[0]?.read || "-",
          write: candidate.languages?.[0]?.write || "-",
          speak: candidate.languages?.[0]?.speak || "-",
          workxp: candidate.workExperience?.[0]?.companyName || "Yok",
          position: candidate.workExperience?.[0]?.position || "-",
          year: candidate.workExperience?.[0]?.year || "-",
          chronicDisease: candidate.healthInfo?.chronicDisease || "Yok",
          disability: candidate.healthInfo?.disability || "Yok",
          isSmoking: smoking,
        };
      });

      setCandidateList(_candidateList);
    });
  };

  const excelExportConfig = {
    mappingFunction: ({
      name,
      surName,
      birthday,
      phoneNumber,
      email,
      gender,
      nationality,
      schoolCity,
      schoolName,
      department,
      clas,
      workxp,
      position,
      year,
      chronicDisease,
      disability,
      isSmoking,
    }) => ({
      Ad: name,
      Soyad: surName,
      "Doğum Tarihi": birthday,
      Telefon: phoneNumber,
      "E-Posta": email,
      Cinsiyet: gender,
      Uyruk: nationality,
      "Okul İli": schoolCity,
      "Okul Adı": schoolName,
      Bölüm: department,
      Sınıf: clas,
      "İş Tecrübesi": workxp,
      Pozisyon: position,
      Yıl: year,
      "Kronik Hastalık": chronicDisease,
      "Engel Durumu": disability,
      "Sigara Kullanımı":
        typeof isSmoking === "boolean"
          ? isSmoking
            ? "İçiyor"
            : "İçmiyor"
          : "Bilinmiyor",
    }),
  };

  return (
    <div className="outer__container">
      <Header title="HrConnect " />

      <Tabs defaultActiveKey="1" type="card">
        <Tabs.TabPane tab="Başvuru Listesi" key="1">
          <div
            className="drt__table"
            style={{
              height: "80vh",
              overflowY: "auto",
            }}
          >
            <div style={{ flex: 2 }}>
              <div className="table__header-container">
                <div className="table__header-left">
                  <img src={DRT_Logo} alt="logo" className="table__big-icon" />
                  <p className="table__header-title">Başvuru Listesi</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div className="table__search-container">
                    <Input
                      className="table__search-input"
                      prefix={<SearchOutlined />}
                      placeholder="Bayi Ara"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length > 1) {
                          setStoreList(
                            originaStoreList.filter((content) =>
                              filterContentOption(value, content)
                            )
                          );
                        } else {
                          setStoreList(originaStoreList);
                        }
                      }}
                      allowClear
                    />
                  </div>
                </div>
                <div className="table__header-right">
                  <Tooltip title="Filtreleri Sıfırla">
                    <button
                      onClick={() => {
                        setClearFiltersClicked(true);
                        setTimeout(() => {
                          setClearFiltersClicked(false);
                        }, 100);
                      }}
                      className="table__small-icon-container"
                    >
                      <img
                        src={clearFilter}
                        alt="clearFilter"
                        className="table__small-icon"
                      />
                    </button>
                  </Tooltip>
                  <Tooltip title="Excel'e Aktar">
                    <button
                      onClick={() =>
                        exportToExcel(selectedItems, excelExportConfig)
                      }
                      className="table__small-icon-container"
                    >
                      <img
                        src={exportUsers}
                        alt="exportUsers"
                        className="table__small-icon"
                      />
                    </button>
                  </Tooltip>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CustomTable
                  tableName={"StoreList"}
                  columns={columnHR}
                  dataSource={candidateList}
                  setSelectedItems={setSelectedItems}
                  clearFiltersClicked={clearFiltersClicked}
                />
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Değerlendirmeler" key="2">
          <SurveyPage />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
