import { Header } from "../components/Header";
import "./AdminSettings.css";

export const AdminSettings = () => {
  return (
    <div className="outer__container">
      <Header title="Yönetici Ayarları" />
      <div className="inner__container"></div>
    </div>
  );
};
