import { useEffect, useState } from "react";
import "./ExpandedDrtID01.css";
import { tR } from "./functions";
import { CustomTable } from "./CustomTable";
import { ExpandedDrtID02 } from "./ExpandedDrtID02";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const yieldGoalName = {
  maximumFertilizers: "Maksimum Verim",
  highFertilizers: "Yüksek Verim",
  optimumFertilizers: "Optimum Verim",
};

const sulamaSaatleri = ["08:00", "12:00", "14:30", "18:00", "21:30"];

const columnsExpandedTable = [
  {
    key: "plantName",
    dataIndex: "plantName",
    title: "Bitki Adı",
    sorter: (a, b) => a.plantName.localeCompare(b.plantName),
  },
  {
    key: "programName",
    dataIndex: "programName",
    title: "Program Adı",
    sorter: (a, b) => a.programName.localeCompare(b.programName),
  },
  {
    key: "irrigationType",
    dataIndex: "irrigationType",
    title: "Sulama Tipi",
    sorter: (a, b) => a.irrigationType.localeCompare(b.irrigationType),
  },
  {
    key: "fertilizerSet",
    dataIndex: "fertilizerSet",
    title: "Verim Grubu",
    sorter: (a, b) => a.fertilizerSet.localeCompare(b.fertilizerSet),
  },
  {
    key: "duration",
    dataIndex: "duration",
    title: "Süre",
    sorter: (a, b) => a.duration.localeCompare(b.duration),
  },
  {
    key: "drtFusionHours",
    dataIndex: "drtFusionHours",
    title: "DRT Fusion Saatleri",
  },
];

export const ExpandedDrtID01 = ({
  record,
  clearFiltersClicked,
  setSelectedItems,
}) => {
  const [programList, setProgramList] = useState(null);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [userPrograms, setUserPrograms] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const _userPrograms = await tR(
          `/schedule-requests/user/${record.id}`,
          "GET"
        );

        setUserPrograms(_userPrograms);
      } finally {
        setIsLoading(false);
      }
    }

    setIsLoading(true);
    fetchData();
  }, [record.id]);

  useEffect(() => {
    getProgramList();
  }, []);

  const getProgramList = async () => {
    const _programList = await tR("/schedule-requests/program", "GET");
    setProgramList(_programList);
  };

  const getProgramName = (programKey) => {
    for (const plantKey in programList) {
      const plant = programList[plantKey];
      const program = plant.programs.find((p) => p.key === programKey);
      if (program) return program.name;
    }
  };

  const getPlantName = (programKey) => {
    for (const plantKey in programList) {
      const plant = programList[plantKey];
      const program = plant.programs.find((p) => p.key === programKey);
      if (program) return plant.plantName;
    }
    return "";
  };

  const expanded01DataSource = userPrograms?.scheduleRequest?.map(
    (schedule) => ({
      id: schedule.id,
      key: schedule.id,
      plantName: getPlantName(schedule.programName),
      irrigationType: schedule.irrigationType || "Damla Sulama",
      programName: getProgramName(schedule.programName),
      fertilizerSet: yieldGoalName[schedule.fertilizerSet],
      duration: schedule.duration + " Ay",
      drtFusionHours: schedule.fusionHours || sulamaSaatleri.join(", "),
    })
  );

  const handleExpand = (record) =>
    setExpandedRowKey((prevKey) => (prevKey === record.id ? null : record.id));

  return (
    <div className="expandedid01__container">
      {isLoading ? (
        <div className="table__load-spinner">
          <Spin indicator={<LoadingOutlined style={{ color: "#AAFF00" }} />} />
        </div>
      ) : (
        <CustomTable
          tableName={"ExpandedID01Table"}
          columns={columnsExpandedTable}
          dataSource={expanded01DataSource}
          setSelectedItems={setSelectedItems}
          clearFiltersClicked={clearFiltersClicked}
          expandable={{
            expandedRowRender: (record) => <ExpandedDrtID02 record={record} />,
            defaultExpandedRowKeys: ["0"],
            expandedRowKeys: [expandedRowKey],
            onExpand: (expanded, record) => handleExpand(record),
            rowExpandable: (record) => record.expandedContent !== "",
          }}
        />
      )}
    </div>
  );
};
