import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { Sidebar } from "./components/Sidebar";
import { ContentManagement } from "./pages/ContentManagement";
import { StoreManagement } from "./pages/StoreManagement";
import { AdminSettings } from "./pages/AdminSettings";
import { DrtID } from "./pages/DrtID";
import { useEffect, useState } from "react";
import { url } from "./components/functions";
import axios from "axios";
import { Login } from "./pages/Login";
import { HrConnect } from "./pages/HrConnect";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = ({ loginPhone, loginPassword }) => {
    axios
      .post(url + "/auth/login", {
        username: loginPhone,
        password: loginPassword,
      })
      .then((response) => {
        console.log(response);
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        setIsLoggedIn(true);
        localStorage.setItem("isLoggedIn", true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    if (token && refreshToken) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        {isLoggedIn && <Sidebar />}
        <Routes>
          {isLoggedIn ? (
            <Route exact path="/" element={<Home />} />
          ) : (
            <Route
              exact
              path="/"
              element={
                <Login
                  handleLogin={handleLogin}
                  setIsLoggedIn={setIsLoggedIn}
                />
              }
            />
          )}
          <Route
            exact
            path="/contentmanagement"
            element={<ContentManagement />}
          />
          <Route exact path="/drtid" element={<DrtID />} />
          <Route exact path="/storemanagement" element={<StoreManagement />} />
          <Route exact path="/hrconnect" element={<HrConnect />} />
          <Route exact path="/adminsettings" element={<AdminSettings />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
