import "./DrtID.css";
import { useEffect, useState } from "react";
import { Header } from "../components/Header";
import phone from "../assets/phone.png";
import pc from "../assets/pc.png";
import { DatePicker, Input, Progress, Select, Tooltip } from "antd";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import DRT_Logo from "../assets/DRT_Logo.png";
import exportUsers from "../assets/exportUsers.png";
import clearFilter from "../assets/clearFilter.png";
import { CustomTable } from "../components/CustomTable";
import { SearchOutlined } from "@ant-design/icons";
import { tR } from "../components/functions";
import dayjs from "dayjs";
import { ExpandedDrtID01 } from "../components/ExpandedDrtID01";
import * as XLSX from "xlsx";
import { filterContentOption } from "../components/functions";
import { useLocation } from "react-router-dom";

const renderVisitorBar = (
  image,
  userType,
  userCount,
  percent,
  fromColor,
  toColor
) => (
  <div className="id__visitors-bar-container">
    <div className="id__visitors-bar-image-container">
      <img src={image} alt="device" className="id__visitors-bar-image" />
    </div>
    <div className="id__visitors-right-container">
      <div className="id__visitors-above-bar">
        <p className="id__visitors-user-type">{userType}</p>
        <p className="id__visitors-user-count">{userCount}</p>
      </div>
      <div className="id__visitors-bar">
        <Progress
          showInfo={false}
          size={[`100%`, 10]}
          trailColor="#d9d9d9"
          percent={percent}
          status="active"
          strokeColor={{ from: fromColor, to: toColor }}
        />
      </div>
    </div>
  </div>
);

const dummyCities = [
  { key: "bursa", value: "bursa", text: "Bursa" },
  { key: "istanbul", value: "istanbul", text: "İstanbul" },
  { key: "ankara", value: "ankara", text: "Ankara" },
  { key: "izmir", value: "izmir", text: "İzmir" },
];

const dummyActiveUsers = {
  total: [
    { name: "Ocak", kullaniciSayisi: 99 },
    { name: "Şubat", kullaniciSayisi: 88 },
    { name: "Mart", kullaniciSayisi: 10 },
    { name: "Nisan", kullaniciSayisi: 352 },
    { name: "Mayıs", kullaniciSayisi: 15 },
    { name: "Haziran", kullaniciSayisi: 541 },
    { name: "Temmuz", kullaniciSayisi: 431 },
    { name: "Ağustos", kullaniciSayisi: 222 },
    { name: "Eylül", kullaniciSayisi: 543 },
    { name: "Ekim", kullaniciSayisi: 42 },
    { name: "Kasım", kullaniciSayisi: 64 },
    { name: "Aralık", kullaniciSayisi: 188 },
  ],
  izmir: [
    { name: "Ocak", kullaniciSayisi: 35 },
    { name: "Şubat", kullaniciSayisi: 88 },
    { name: "Mart", kullaniciSayisi: 51 },
    { name: "Nisan", kullaniciSayisi: 42 },
    { name: "Mayıs", kullaniciSayisi: 235 },
    { name: "Haziran", kullaniciSayisi: 453 },
    { name: "Temmuz", kullaniciSayisi: 12 },
    { name: "Ağustos", kullaniciSayisi: 52 },
    { name: "Eylül", kullaniciSayisi: 532 },
    { name: "Ekim", kullaniciSayisi: 66 },
    { name: "Kasım", kullaniciSayisi: 12 },
    { name: "Aralık", kullaniciSayisi: 222 },
  ],
  istanbul: [
    { name: "Ocak", kullaniciSayisi: 34 },
    { name: "Şubat", kullaniciSayisi: 88 },
    { name: "Mart", kullaniciSayisi: 10 },
    { name: "Nisan", kullaniciSayisi: 111 },
    { name: "Mayıs", kullaniciSayisi: 323 },
    { name: "Haziran", kullaniciSayisi: 643 },
    { name: "Temmuz", kullaniciSayisi: 234 },
    { name: "Ağustos", kullaniciSayisi: 222 },
    { name: "Eylül", kullaniciSayisi: 343 },
    { name: "Ekim", kullaniciSayisi: 535 },
    { name: "Kasım", kullaniciSayisi: 126 },
    { name: "Aralık", kullaniciSayisi: 188 },
  ],
  bursa: [
    { name: "Ocak", kullaniciSayisi: 16 },
    { name: "Şubat", kullaniciSayisi: 11 },
    { name: "Mart", kullaniciSayisi: 10 },
    { name: "Nisan", kullaniciSayisi: 436 },
    { name: "Mayıs", kullaniciSayisi: 323 },
    { name: "Haziran", kullaniciSayisi: 30 },
    { name: "Temmuz", kullaniciSayisi: 64 },
    { name: "Ağustos", kullaniciSayisi: 11 },
    { name: "Eylül", kullaniciSayisi: 5 },
    { name: "Ekim", kullaniciSayisi: 323 },
    { name: "Kasım", kullaniciSayisi: 126 },
    { name: "Aralık", kullaniciSayisi: 53 },
  ],
  ankara: [
    { name: "Ocak", kullaniciSayisi: 6 },
    { name: "Şubat", kullaniciSayisi: 88 },
    { name: "Mart", kullaniciSayisi: 10 },
    { name: "Nisan", kullaniciSayisi: 472 },
    { name: "Mayıs", kullaniciSayisi: 323 },
    { name: "Haziran", kullaniciSayisi: 30 },
    { name: "Temmuz", kullaniciSayisi: 444 },
    { name: "Ağustos", kullaniciSayisi: 222 },
    { name: "Eylül", kullaniciSayisi: 543 },
    { name: "Ekim", kullaniciSayisi: 242 },
    { name: "Kasım", kullaniciSayisi: 126 },
    { name: "Aralık", kullaniciSayisi: 188 },
  ],
};

const visitorData = [
  { name: "Mobil", value: 680 },
  { name: "Web", value: 554 },
];

const totalVisitors = visitorData[0].value + visitorData[1].value;

const columnsUsers = [
  {
    key: "id",
    dataIndex: "id",
    title: "ID",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Ad",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    key: "surname",
    dataIndex: "surname",
    title: "Soyad",
    sorter: (a, b) => a.surname.localeCompare(b.surname),
  },
  {
    dataIndex: "mobile",
    title: "Cep Telefonu",
  },
  {
    dataIndex: "email",
    title: "E-Posta",
  },
  {
    dataIndex: "company",
    title: "İşletme",
    sorter: (a, b) => a.company.localeCompare(b.company),
  },
  {
    dataIndex: "City",
    title: "İl",
    sorter: (a, b) => a.City.localeCompare(b.City),
  },
  {
    dataIndex: "District",
    title: "İlçe",
    sorter: (a, b) => a.District.localeCompare(b.District),
  },
  {
    dataIndex: "Province",
    title: "Mahalle",
    sorter: (a, b) => a.Province.localeCompare(b.Province),
  },
  {
    dataIndex: "Street",
    title: "Sk/Cadde",
  },
  {
    dataIndex: "Building",
    title: "No",
  },
  {
    key: "araziKonumu",
    dataIndex: "araziKonumu",
    title: "Arazi Konumu",
    render: (_, record) => (
      <p
        onClick={() => {
          window.open(
            `https://www.google.com/maps/search/?api=1&query=${record.Latitude},${record.Longitude}`
          );
        }}
        className="table__link"
      >
        {record.Latitude &&
          record.Longitude &&
          record.Latitude + ", " + record.Longitude}
      </p>
    ),
  },
];

export const DrtID = () => {
  let { state } = useLocation();
  const [shownActiveUsers, setShownActiveUsers] = useState(null);
  const [year, setYear] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [originalPeopleList, setOriginalPeopleList] = useState([]);
  const [peopleList, setPeopleList] = useState([]);
  const [clearFiltersClicked, setClearFiltersClicked] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [clickedUser, setClickedUser] = useState(null);

  useEffect(() => {
    getPeopleList();
    setShownActiveUsers(dummyActiveUsers.total);
  }, []);

  const getPeopleList = async () => {
    const _peopleList = await tR("/users", "GET");

    setPeopleList(_peopleList);
    setOriginalPeopleList(_peopleList);
  };

  useEffect(() => {
    setExpandedRowKey(state?.id);
  }, [state]);

  const handleExpand = (record) => {
    setExpandedRowKey((prevKey) => (prevKey === record.id ? null : record.id));
    setClickedUser(record);
  };

  const exportToExcel = (selectedItems, clickedUser) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    let exportData = [];

    if (selectedItems && selectedItems[0] && selectedItems[0].plantName) {
      exportData = selectedItems.map(
        ({
          plantName,
          programName,
          irrigationType,
          fertilizerSet,
          duration,
          drtFusionHours,
        }) => ({
          "Kullanıcı Adı": clickedUser?.name,
          "Bitki Adı": plantName,
          "Program Adı": programName,
          "Sulama Tipi": irrigationType,
          "Verim Grubu": fertilizerSet,
          Süre: duration,
          "DRT Fusion Saatleri": drtFusionHours,
        })
      );
    } else if (selectedItems && selectedItems[0]) {
      exportData = selectedItems.map(
        ({
          id,
          name,
          surname,
          mobile,
          email,
          company,
          City,
          District,
          Province,
          Street,
          Building,
          Latitude,
          Longitude,
        }) => ({
          ID: id,
          İsim: name,
          Soyisim: surname,
          "Cep Telefonu": mobile,
          "E-Posta": email,
          Şirket: company,
          İl: City,
          İlçe: District,
          Mahalle: Province,
          "Sokak/Cadde": Street,
          Numara: Building,
          "Arazi Enlem": Latitude,
          "Arazi Boylam": Longitude,
        })
      );
    }

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const fileName = "data" + fileExtension;

    const a = document.createElement("a");
    a.href = URL.createObjectURL(data);
    a.download = fileName;

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  };

  return (
    <div className="outer__container">
      <Header title="DRT ID" />
      <div className="id__content">
        <div className="page-blur01"></div>
        <div className="id__top">
          <div className="id__top-left">
            <div className="id__active-top">
              <p className="id__active-header">Aktif Kullanıcılar</p>
              <div className="id__active-top-right">
                <div>
                  <Select
                    notFoundContent={
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={require("../assets/danger.png")}
                          alt="danger"
                          style={{ width: 40, height: 40, opacity: 0.5 }}
                        />
                        <p>Lokasyon Bulunamadı.</p>
                      </div>
                    }
                    className="id__active-select-location"
                    showSearch
                    allowClear
                    placeholder="Tüm Lokasyonlar"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setShownActiveUsers(
                        dummyActiveUsers[value] ?? dummyActiveUsers.total
                      );
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={dummyCities.map((item) => ({
                      value: item.key,
                      label: item.text,
                    }))}
                  />
                </div>
                <div>
                  <DatePicker
                    placeholder="Yıl Seçiniz"
                    defaultValue={dayjs()}
                    className="id__active-select-date"
                    onChange={(date, dateString) => setYear(dateString)}
                    picker="year"
                    disabledDate={(current) =>
                      current && current < dayjs("2024-01-01").startOf("year")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="id__active-bottom">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={shownActiveUsers} barSize={25}>
                  <defs>
                    <linearGradient
                      id="barChartColor"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="100%"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stopColor="#FF4D00" />
                      <stop offset="0.33" stopColor="#FFB800" />
                      <stop offset="0.66" stopColor="#00FF0F" />
                      <stop offset="1" stopColor="#002C0300" />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    style={{
                      fill: "#fff",
                      fontWeight: 300,
                      fontSize: `clamp(8px, 0rem + 0.5vw, 12px)`,
                    }}
                  />
                  <YAxis
                    style={{
                      fill: "#fff",
                      fontWeight: 300,
                      fontSize: `clamp(8px, 0rem + 0.5vw, 12px)`,
                    }}
                  />
                  <Bar
                    dataKey="kullaniciSayisi"
                    fill="url(#barChartColor)"
                    label={{ fill: "white", fontSize: 12, position: "top" }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="id__top-right">
            <div className="id__visitors-text-container">
              <p className="id__visitors-header">Toplam Ziyaretçi</p>
              <p className="id__visitors-number">
                {totalVisitors.toLocaleString("tr-TR")}
              </p>
            </div>
            <div className="id__visitors-bars">
              {renderVisitorBar(
                phone,
                "Mobil Kullanıcı",
                visitorData[0].value,
                (visitorData[0].value / totalVisitors) * 100,
                "#33FF00",
                "#FFB800"
              )}
              {renderVisitorBar(
                pc,
                "Web Kullanıcı",
                visitorData[1].value,
                (visitorData[1].value / totalVisitors) * 100,
                "#FFFFFFC4",
                "#00FFC2"
              )}
            </div>
          </div>
        </div>
        <div className="drt__table" style={{ flex: 2 }}>
          <div className="table__header-container">
            <div className="table__header-left">
              <img src={DRT_Logo} alt="logo" className="table__big-icon" />
              <p className="table__header-title">Kullanıcı Veri Sistemi</p>
            </div>
            <div className="table__header-middle">
              <div className="table__search-container">
                <Input
                  className="table__search-input"
                  prefix={<SearchOutlined />}
                  placeholder="Kullanıcı Ara"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 1) {
                      setPeopleList(
                        originalPeopleList.filter((content) =>
                          filterContentOption(value, content)
                        )
                      );
                    } else {
                      setPeopleList(originalPeopleList);
                    }
                  }}
                  allowClear
                />
              </div>
            </div>
            <div className="table__header-right">
              <Tooltip title="Filtreleri Sıfırla">
                <button
                  onClick={() => {
                    setClearFiltersClicked(true);
                    setTimeout(() => {
                      setClearFiltersClicked(false);
                    }, 100);
                  }}
                  className="table__small-icon-container"
                >
                  <img
                    src={clearFilter}
                    alt="clearFilter"
                    className="table__small-icon"
                  />
                </button>
              </Tooltip>
              <Tooltip title="Excel'e Aktar">
                <button
                  className="table__small-icon-container"
                  onClick={() => exportToExcel(selectedItems, clickedUser)}
                >
                  <img
                    src={exportUsers}
                    alt="exportUsers"
                    className="table__small-icon"
                  />
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="table__container">
            <CustomTable
              tableName={"UserList"}
              columns={columnsUsers}
              dataSource={peopleList}
              setSelectedItems={setSelectedItems}
              clearFiltersClicked={clearFiltersClicked}
              expandable={{
                expandedRowRender: (record) => (
                  <ExpandedDrtID01
                    record={record}
                    clearFiltersClicked={clearFiltersClicked}
                    setSelectedItems={setSelectedItems}
                  />
                ),
                defaultExpandedRowKeys: ["0"],
                expandedRowKeys: [expandedRowKey],
                onExpand: (expanded, record) => handleExpand(record),
                rowExpandable: (record) => record.expandedContent !== "",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
