import "./Header.css";

export const Header = ({ title }) => {
  return (
    <div className="header__container">
      <div className="header__text-container">
        <p className="header__text">{title}</p>
      </div>
      <button
        onClick={() => {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("isLoggedIn");
          window.location.href = "/";
        }}
        className="header__button"
      >
        Çıkış Yap
      </button>
    </div>
  );
};
